import React from "react";
import Category from "../components/Category";
import setPageTitle from "../functions/setTitle";

function CategoryProduct() {
  const title = setPageTitle("category-product");
  document.title = title;
  return <Category />;
}

export default CategoryProduct;
