import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { createBrowserHistory } from "history";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import RecentActorsOutlinedIcon from "@mui/icons-material/RecentActorsOutlined";

function Sidebarr() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("");
  const [typeID, setTypeID] = useState(null);
  const [newTypeID, setNewTypeID] = useState(null);
  const history = createBrowserHistory();

  const handleItemClick = (newTypeID) => {
    setTypeID(newTypeID);
    setNewTypeID(newTypeID);
    if (newTypeID === 1) {
      history.push(`/category-product?newTypeID=${newTypeID}`);
    } else if (newTypeID === 2) {
      history.push(`/category-solution?newTypeID=${newTypeID}`);
    } else if (newTypeID === 3) {
      history.push(`/category-project?newTypeID=${newTypeID}`);
    } else if (newTypeID === 4) {
      history.push(`/category-service?newTypeID=${newTypeID}`);
    }
  };

  const Item = ({ title, to, icon, typeID, newTypeID }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const onItemClick = () => {
      setSelected(title);
      handleItemClick(typeID);
    };

    return (
      <MenuItem
        active={selected === title}
        style={{
          color: colors.grey[100],
        }}
        onClick={onItemClick}
        icon={icon}
      >
        <Typography>{title}</Typography>
        <Link to={to} />
      </MenuItem>
    );
  };

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: "#1F2A40 !important",
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active span": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: "#fff",
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color="#fff">
                  ADMINIS
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {/* USER  */}
          {!isCollapsed && (
            <Box mb="25px">
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color="#fff"
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  Parkplyus
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  Admin Panel
                </Typography>
              </Box>
            </Box>
          )}

          {/* MENU ITEMS  */}
          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to="/dashboard"
              icon={<HomeOutlinedIcon />}
              typeID={null}
            />
            <Typography variant="h6" color="#fff" sx={{ m: "15px 0 5px 20px" }}>
              Səhifələr
            </Typography>
            <Item title="Slider" to="/slider" icon={<ContactsOutlinedIcon />} />
            <Item
              title="Bizimlə əlaqə"
              to="/contact"
              icon={<ContactsOutlinedIcon />}
            />
            <Item
              title="Haqqımızda"
              to="/about"
              icon={<PeopleOutlinedIcon />}
            />
            <Item title="Xəbərlər" to="/news" icon={<FeedOutlinedIcon />} />

            <Typography variant="h6" color="#fff" sx={{ m: "15px 0 5px 20px" }}>
              Məhsul (Product)
            </Typography>
            <Item
              title="Məhsul kateqoriyası"
              to={`/category-product/${typeID}`}
              icon={<RecentActorsOutlinedIcon />}
              typeID={1}
            />
            <Item
              title="Məhsul"
              to="/product"
              icon={<RecentActorsOutlinedIcon />}
            />
            <Typography variant="h6" color="#fff" sx={{ m: "15px 0 5px 20px" }}>
              Solution
            </Typography>
            <Item
              title="Solution kateqoriyası"
              to={`/category-solution/${typeID}`}
              icon={<RecentActorsOutlinedIcon />}
              typeID={2}
            />
            <Item
              title="Solution"
              to="/solution"
              icon={<RecentActorsOutlinedIcon />}
            />
            <Typography variant="h6" color="#fff" sx={{ m: "15px 0 5px 20px" }}>
              Layihə (Project)
            </Typography>
            <Item
              title="Layihə kateqoriyası"
              to={`/category-project/${typeID}`}
              icon={<RecentActorsOutlinedIcon />}
              typeID={3}
            />
            <Item
              title="Layihə"
              to="/project"
              icon={<RecentActorsOutlinedIcon />}
            />
            <Typography variant="h6" color="#fff" sx={{ m: "15px 0 5px 20px" }}>
              Xidmət (Service)
            </Typography>
            <Item
              title="Xidmət kateqoriyası"
              to={`/category-service/${typeID}`}
              icon={<RecentActorsOutlinedIcon />}
              typeID={4}
            />
            <Item
              title="Xidmət"
              to="/service"
              icon={<RecentActorsOutlinedIcon />}
            />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
}

export default Sidebarr;
