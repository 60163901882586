import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Modal, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../theme";
import Header from "../components/Header";
import { Link, useNavigate } from "react-router-dom";
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import { handleUnauthorizedResponse } from "../functions/tokenService";
import { useRequireToken } from "../functions/authUtils";
import useCheckTokenExpiration from "../functions/CheckTokenExpiration";
import apiToken from "../functions/tokenUtils";
import setPageTitle from "../functions/setTitle";
import * as consts from "../consts/Consts";
import DeleteConfirmationModal from "../components/DeleteConfirmationModal";

const News = () => {
  const title = setPageTitle("news");
  document.title = title;
  useRequireToken();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [newsData, setNewsData] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState("az");
  const [loading, setLoading] = useState(true);

  const apiUrl = `${consts.API_URL_SITE}/news`;

  const navigate = useNavigate();

  const checkTokenExpiration = useCheckTokenExpiration();

  useEffect(() => {
    checkTokenExpiration();
  }, [checkTokenExpiration]);

  const handleEditClick = (params) => {
    const itemId = params.row.id;
    navigate(`/edit/${itemId}`);
  };

  const handleDeleteClick = (params) => {
    setSelectedItem(params.row);
    setDeleteModalOpen(true);
  };

  const handleDelete = async () => {
    setDeleteModalOpen(false);

    if (!selectedItem) {
      return;
    }

    const deleteUrl = `${consts.API_URL}/news/${selectedItem.id}`;

    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${apiToken()}`,
        "Accept-Language": currentLanguage,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(deleteUrl, requestOptions);

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(
          `HTTP error! Status: ${response.status}, Message: ${errorResponse.message}`
        );
      }
      console.log("Успешно удалено");

      setNewsData((prevNewsData) =>
        prevNewsData.filter((news) => news.id !== selectedItem.id)
      );
    } catch (error) {
      console.error("Ошибка при удалении данных:", error);
    }
  };

  useEffect(() => {
    const headers = {
      //Authorization: `Bearer ${apiToken()}`,
      "Accept-Language": currentLanguage,
      "Content-Type": "application/json",
    };

    fetch(apiUrl, {
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setNewsData(data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Ошибка при получении данных:", error);
        setLoading(false);
      });
  }, [apiUrl, apiToken, currentLanguage]);

  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "image",
      headerName: "Şəkil",
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <img src={params.value} alt="Şəkil" width={50} height={50} />
          </Grid>
        </Grid>
      ),
    },
    {
      field: "slug",
      headerName: "Başlıq",
      flex: 1.5,
      cellClassName: "name-column--cell",
    },
    {
      field: "title",
      headerName: "Mətn",
      headerAlign: "left",
      align: "left",
      flex: 2.5,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <span>
          {params.value === 1 ? "Aktiv" : params.value === 0 ? "Deaktiv" : ""}
        </span>
      ),
    },
    {
      field: "actions",
      headerName: "",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEditClick(params)}
          >
            Düzəliş et
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDeleteClick(params)}
          >
            Sil
          </Button>
        </div>
      ),
    },
  ];

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <>
      <DeleteConfirmationModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={handleDelete}
      />
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Xəbərlər" subtitle="Xəbərlərin siyahısı" />
          <Link to="add-article">
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.primary[100],
              }}
              startIcon={<LibraryAddOutlinedIcon />}
            >
              Əlavə et
            </Button>
          </Link>
        </Box>

        <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            rows={newsData}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
          />
        </Box>
      </Box>
    </>
  );
};

export default News;
