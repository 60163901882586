import React from "react";
import { Tabs, Tab, useTheme } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { tokens } from "../theme";

function TabsLanguage({ currentLanguage, onLanguageChange }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Tabs
      value={currentLanguage}
      onChange={onLanguageChange}
      textColor="primary"
      sx={{
        "& button.Mui-selected": {
          color: colors.grey[100],
        },
        "& button": {
          fontSize: "16px",
        },
        "& span.MuiTabs-indicator": {
          backgroundColor: colors.grey[100],
        },
        "& MuiButtonBase-root .MuiTab-root.MuiSelected": {
          color: "#fff !important",
          marginBottom: "20px",
        },
        "& MuiButtonBase-root .MuiTab-root.MuiSelected": {
          color: "#fff !important",
          marginBottom: "20px",
        },

      }}
    >
      <Tab label="Az" value="az" />
      <Tab label="En" value="en" />
      <Tab label="Ru" value="ru" />
    </Tabs>
  );
}

export default TabsLanguage;
