import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { tokens } from "../theme";
import {
  Box,
  TextField,
  useTheme,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "./Header";
import StickyHeaderEdit from "./StickyHeaderEdit";
import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";
import { createBrowserHistory } from "history";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { handleUnauthorizedResponse } from "../functions/tokenService";
import { useRequireToken } from "../functions/authUtils";
import useCheckTokenExpiration from "../functions/CheckTokenExpiration";
import apiToken from "../functions/tokenUtils";
import * as consts from "../consts/Consts";

function SubCategoryEdit() {
  useRequireToken();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [getData, setgetData] = useState({});

  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentLanguage, setCurrentLanguage] = useState("az");
  const [contactData, setContactData] = useState({});
  const [loading, setLoading] = useState(true);
  const [inputData, setInputData] = useState({});
  const [pageLoaded, setPageLoaded] = useState(false);
  const [activeStatus, setActiveStatus] = useState("");
  const [status, setStatus] = useState(activeStatus);

  const [category, setCategory] = useState({});
  const [activeCategory, setActiveCategory] = useState("");

  const [selectedCategory, setSelectedCategory] = useState(activeCategory);
  const [selectedCategoryID, setSelectedCategoryID] = useState(null);
  const [activeCategoryID, setActiveCategoryID] = useState(null);
  const [subCategoryName, setSubCategoryName] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);
  const params = useParams();
  const { itemId } = params;

  const history = createBrowserHistory();
  const location = history.location;
  const searchParams = new URLSearchParams(location.search);
  const newTypeID = searchParams.get("newTypeID");

  const [typeName, setTypeName] = useState("");

  useEffect(() => {
    switch (newTypeID) {
      case "1":
        setTypeName("product");
        break;
      case "2":
        setTypeName("solution");
        break;
      case "3":
        setTypeName("project");
        break;
      case "4":
        setTypeName("service");
        break;
      default:
        setTypeName("");
        break;
    }
  }, [newTypeID]);

  const apiUrl = `${consts.API_URL}/category/${typeName}/${itemId}/edit`;
  const apiUrlPut = `${consts.API_URL}/category/${typeName}/${itemId}`;
  const apiCategoryGet = `${consts.API_URL_SITE}/category/${typeName}`;

  const checkTokenExpiration = useCheckTokenExpiration();

  useEffect(() => {
    checkTokenExpiration();
  }, [checkTokenExpiration]);

  useEffect(() => {
    if (typeName !== "") {
      setLoading(false);
      fetchDataForLanguage(currentLanguage);
      fetchDataForCategory(currentLanguage);
    }
  }, [typeName]);

  //   useEffect(() => {
  //     console.log(apiUrl);
  //     console.log(apiUrlPut);
  //     console.log(apiCategoryGet);
  //   }, [apiUrl, apiUrlPut, apiCategoryGet]);

  const handleStatusChange = (event) => {
    const selectedStatus = event.target.value;
    setActiveStatus(selectedStatus);
    setStatus(selectedStatus);
  };

  const handleCategoryChange = (event) => {
    const selectedCategoryName = event.target.value;
    const selectedCategoryItem = category.find(
      (item) => item.name === selectedCategoryName
    );
    const selectedCategoryID = selectedCategoryItem.id;
    setActiveCategory(selectedCategoryName);
    setSelectedCategory(selectedCategoryName);
    setSelectedCategoryID(selectedCategoryID);
  };

  useEffect(() => {
    for (const key in category) {
      if (category && category.hasOwnProperty(key)) {
        const categoryItem = category[key];
        if (categoryItem && categoryItem.id === activeCategoryID) {
          setActiveCategory(categoryItem.name);
          break;
        }
      }
    }
  }, [category, activeCategoryID]);

  const fetchDataForCategory = (language) => {
    const headers = {
      Authorization: `Bearer ${apiToken()}`,
      "Accept-Language": language,
      "Content-Type": "application/json",
    };

    fetch(apiCategoryGet, {
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setCategory(data);
        setLoading(false);
      })
      .catch((error) => {
        //   console.error("Ошибка при получении данных:", error);
        setLoading(false);
      });
  };

  const handleLanguageChange = (event, newValue) => {
    setCurrentLanguage(newValue);

    if (pageLoaded) {
      setInputData((prevInputData) => {
        const currentInputData = { ...prevInputData };
        return currentInputData;
      });
    } else {
      fetchDataForLanguage(newValue);
    }
  };

  useEffect(() => {
    if (!loading && getData) {
      setSelectedCategory(activeCategory);
    }
  }, [loading, getData, activeCategory]);

  const fetchDataForLanguage = (language) => {
    const headers = {
      Authorization: `Bearer ${apiToken()}`,
      "Accept-Language": language,
      "Content-Type": "application/json",
    };

    fetch(apiUrl, {
      headers,
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            handleUnauthorizedResponse(response);
          } else {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
        }
        return response.json();
      })
      .then((data) => {
        //   console.log("Ответ на GET-запрос:", data);
        if (data && data) {
          setgetData(data.data);
          const azTranslation = data.data.translations.find(
            (t) => t.lang_code === "az"
          );

          if (azTranslation) {
            setSubCategoryName(azTranslation.name);
          }
          setLoading(false);

          const commonStatus = data.data.status;
          const commonCategory = data.data.parent_id || "";
          setActiveStatus(commonStatus);
          setActiveCategoryID(commonCategory);
          if (pageLoaded) {
            setInputData((prevInputData) => ({
              ...prevInputData,
              status: commonStatus,
              parent_id: commonCategory,
            }));
          } else {
            const updatedInputData = {};
            const languages = ["az", "ru", "en"];

            languages.forEach((lang) => {
              updatedInputData[lang] = {
                name:
                  data.data.translations.find((t) => t.lang_code === lang)
                    ?.name || "",
                status: commonStatus,
                parent_id: commonCategory,
              };
            });

            setInputData(updatedInputData);
            setPageLoaded(true);
          }
        } else {
          //  console.error("Получены некорректные данные:", data);
        }
      })
      .catch((error) => {
        //   console.error("Ошибка при получении данных:", error);

        setLoading(false);
      });
  };

  useEffect(
    () => {
      fetchDataForLanguage(currentLanguage);
      fetchDataForCategory(currentLanguage);
    },
    [currentLanguage],
    [typeName]
  );

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setInputData((prevTranslations) => {
      const currentTranslations = { ...prevTranslations };
      currentTranslations[currentLanguage] = {
        ...currentTranslations[currentLanguage],
        [name]: value,
      };
      return currentTranslations;
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const updatedData = {
      parent_id: selectedCategoryID ? selectedCategoryID : activeCategoryID,
      status: activeStatus,
      translations: [],
    };

    const languages = ["az", "ru", "en"];
    languages.forEach((lang) => {
      updatedData.translations.push({
        lang_code: lang,
        name: inputData[lang]?.name || "",
      });
    });

    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${apiToken()}`,
        "Accept-Language": currentLanguage,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedData),
    };

    fetch(apiUrlPut, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        //   console.log("Успешный ответ:", data);
        setSuccessMessage("Forma uğurla göndərildi!");
      })
      .catch((error) => {
        //   console.error("Ошибка при отправке PUT запроса:", error);
        setErrorMessage("Xəta baş verdi");
      });
  };

  const validateForm = () => {
    if (
      !inputData.az?.name ||
      !inputData.en?.name ||
      !inputData.ru?.name ||
      activeStatus === null ||
      activeStatus === undefined
    ) {
      setErrorMessage(" Məlumatlar bütün dillərdə doldurulmalıdır.");
      setIsFormSubmitted(true);

      return false;
    }

    setErrorMessage("");
    return true;
  };

  const handleDeleteClick = (itemId) => {
    setSelectedItem(itemId);
    setDeleteModalOpen(true);
  };

  const handleDelete = async () => {
    setDeleteModalOpen(false);

    if (!selectedItem) {
      return;
    }

    const deleteUrl = `${consts.API_URL}/category/${typeName}/${itemId}`;

    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${apiToken()}`,
        "Accept-Language": currentLanguage,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(deleteUrl, requestOptions);

      if (!response.ok) {
        if (response.status === 401) {
          handleUnauthorizedResponse(response);
        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      }
    } catch (error) {}
  };

  return (
    <>
      <DeleteConfirmationModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={handleDelete}
      />
      <SuccessModal
        successMessage={successMessage}
        onClose={() => setSuccessMessage("")}
      />
      <ErrorModal
        errorMessage={errorMessage}
        onClose={() => setErrorMessage("")}
      />
      <Box m="20px">
        <Header title="Alkateqoriya" subtitle="Altkateqoriyaya düzəliş et" />

        {loading ? (
          <p>Загрузка...</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <Box
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": {
                  gridColumn: isNonMobile ? undefined : "span 4",
                  width: "100%",
                  marginBottom: "20px",
                },
                "& .MuiBox-root": {
                  display: "flex",
                  flexWrap: "wrap",
                },
                "& > div.Mui-focused": {
                  outline: "1 !important",
                  borderColor: colors.grey[100],
                },
                "& label.Mui-focused ": {
                  color: colors.grey[100],
                },
                "& label.Mui-focused ": {
                  color: colors.grey[100],
                },
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={activeStatus}
                  onChange={handleStatusChange}
                >
                  <MenuItem value={1}>Aktiv</MenuItem>
                  <MenuItem value={0}>Deaktiv</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Kateqoriya
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={activeCategory}
                  onChange={handleCategoryChange}
                >
                  {Object.keys(category).map((lang) => (
                    <MenuItem key={lang} value={category[lang].name}>
                      {category[lang].name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <StickyHeaderEdit
              currentLanguage={currentLanguage}
              handleLanguageChange={handleLanguageChange}
              handleDeleteClick={handleDeleteClick}
              itemId={itemId}
            />
            <Box
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": {
                  gridColumn: isNonMobile ? undefined : "span 4",
                  width: "100%",
                  marginBottom: "20px",
                },
                "& .MuiBox-root": {
                  display: "flex",
                  flexWrap: "wrap",
                },
                "& > div.Mui-focused": {
                  outline: "1 !important",
                  borderColor: colors.grey[100],
                },
                "& label.Mui-focused ": {
                  color: colors.grey[100],
                },
                "& label.Mui-focused ": {
                  color: colors.grey[100],
                },
              }}
            >
              <TextField
                label="Ad"
                name="name"
                value={inputData[currentLanguage]?.name || ""}
                onChange={handleInputChange}
                multiline
                fullWidth
                error={!inputData[currentLanguage]?.name && isFormSubmitted}
              />
            </Box>
          </form>
        )}
      </Box>
    </>
  );
}

export default SubCategoryEdit;
