import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Modal, Typography, useTheme } from "@mui/material";
// import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../theme";
import Header from "../components/Header";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import SuccessAlert from "../components/SuccessAlert";
import { createBrowserHistory } from "history";
import * as myConst from "../consts/Consts";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { handleUnauthorizedResponse } from "../functions/tokenService";
import { useRequireToken } from "../functions/authUtils";
import useCheckTokenExpiration from "../functions/CheckTokenExpiration";
import apiToken from "../functions/tokenUtils";
import * as consts from "../consts/Consts";

function Category() {
  useRequireToken();
  const [newsData, setNewsData] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState("az");
  const [loading, setLoading] = useState(true);
  const history = createBrowserHistory();
  const location = history.location;
  const searchParams = new URLSearchParams(location.search);
  const newTypeID = searchParams.get("newTypeID");

  const params = useParams();

  const [successMessage, setSuccessMessage] = useState(
    searchParams.get("successMessage")
  );
  const [typeName, setTypeName] = useState("");

  useEffect(() => {
    switch (newTypeID) {
      case "1":
        setTypeName("product");
        break;
      case "2":
        setTypeName("solution");
        break;
      case "3":
        setTypeName("project");
        break;
      case "4":
        setTypeName("service");
        break;
      default:
        setTypeName("");
        break;
    }
  }, [newTypeID]);

  const apiUrl = `${consts.API_URL_SITE}/category/${typeName}`;

  const checkTokenExpiration = useCheckTokenExpiration();

  useEffect(() => {
    checkTokenExpiration();
  }, [checkTokenExpiration]);

  const navigate = useNavigate();

  const handleEditClick = (params) => {
    const itemId = params.row.id;
    navigate(`/edit-category/${itemId}?newTypeID=${newTypeID}`);
    //  history.push(`/edit-category/${itemId}?newTypeID=${newTypeID}`);
  };

  const handleSubCategoryClick = (a) => {
    const itemId = a.id;
    navigate(`/edit-subcategory/${itemId}?newTypeID=${newTypeID}`);
  };

  useEffect(() => {
    const headers = {
      "Accept-Language": currentLanguage,
      "Content-Type": "application/json",
    };

    fetch(apiUrl, {
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setNewsData(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [apiUrl, currentLanguage]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      cellClassName: "name-column--cell",
    },
    {
      field: "name",
      headerName: "Kateqoriya",
      flex: 1,
      cellClassName: "name-column--cell",
      width: "15%",

      renderCell: (params) => (
        <span
          className="table-subcategory"
          onClick={() => handleEditClick(params)}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: "subcategory",
      headerName: "Altkateqoriya",
      flex: 1,
      sortable: false,
      cellClassName: "subcategory-cell",
      width: "15%",

      renderCell: (params) => {
        const findCategory = newsData.find((cat) => cat.id === params.row.id);
        if (findCategory && findCategory.sub) {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "max-content !important",
                overflow: "visible !important",
              }}
            >
              {findCategory.sub.map((a) => (
                <span
                  key={a.id}
                  className="table-subcategory"
                  onClick={() => handleSubCategoryClick(a)}
                  style={{
                    cursor: "pointer",
                    marginBottom: "8px",
                  }}
                >
                  {a.name}
                </span>
              ))}
            </div>
          );
        } else {
          return <span>Altkateqoriya yoxdur</span>;
        }
      },
    },

    {
      field: "status",
      headerName: "Status",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <span>
          {params.value === 1 ? "Aktiv" : params.value === 0 ? "Deaktiv" : ""}
        </span>
      ),
    },
    //  {
    //    field: "actions",
    //    headerName: "",
    //    flex: 0.2,
    //    sortable: false,
    //    cellClassName: "name-column--cell",
    //    renderCell: (params) => (
    //      <div>
    //        <Button
    //          variant="contained"
    //          color="primary"
    //          onClick={() => handleEditClick(params)}
    //        >
    //          Düzəliş et
    //        </Button>
    //        <Button
    //          variant="contained"
    //          color="error"
    //          onClick={() => handleDeleteClick(params)}
    //        >
    //          Sil
    //        </Button>
    //      </div>
    //    ),
    //  },
  ];

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <>
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header
            title="Kateqoriya ve altkateqoriya"
            subtitle="Kateqoriya ve altkateqoriyası siyahısı"
          />
          <Link to={`/add-category?newTypeID=${newTypeID}`}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.primary[100],
              }}
              startIcon={<LibraryAddOutlinedIcon />}
            >
              Əlavə et
            </Button>
          </Link>
        </Box>
        {successMessage && (
          <SuccessAlert
            message={successMessage}
            onClose={() => setSuccessMessage("")}
          />
        )}
        <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
              fontSize: "18px !important",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
              fontSize: "14px !important",
            },
            "& .name-column--cell": {
              fontSize: "16px !important",
            },
            "& button.MuiButtonBase-root": {
              marginRight: "10px",
            },
            "& .MuiDataGrid-row": {
              maxHeight: "max-content !important",
              minHeight: "max-content !important",
            },
          }}
        >
          <DataGrid
            {...newsData}
            density="comfortable"
            slots={{
              toolbar: CustomToolbar,
            }}
            rows={newsData}
            columns={columns}
          />
        </Box>
      </Box>
    </>
  );
}

export default Category;

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}
