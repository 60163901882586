import React, { useState, useEffect, useContext } from "react";
import { Box, Button, TextField, IconButton, useTheme } from "@mui/material";
import { useForm } from "react-hook-form";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { tokens, ColorModeContext } from "../theme";
import { saveToken, clearToken } from "../functions/tokenService";
import useCheckTokenExpiration from "../functions/CheckTokenExpiration";
import setPageTitle from "../functions/setTitle";
import * as consts from "../consts/Consts";

const Login = () => {
  const title = setPageTitle("login");
  document.title = title;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [loginError, setLoginError] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const checkTokenExpiration = useCheckTokenExpiration();

  useEffect(() => {
    checkTokenExpiration();
  }, [checkTokenExpiration]);

  const apiUrlLogin = `${consts.API_URL}/login`;

  const handleFormSubmit = async (data) => {
    try {
      const response = await fetch(apiUrlLogin, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "accept-language": "az",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const responseData = await response.json();
        const { token } = responseData.data;
        localStorage.setItem("token", token);
        navigate("/dashboard");
        saveToken(JSON.stringify(token));
      } else {
        setLoginError(true);
      }
    } catch (error) {
      console.error("Ошибка при входе:", error);
      setLoginError(true);
    }
  };

  return (
    <div className="login">
      <div className="login__top" style={{ textAlign: "right" }}>
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>{" "}
      </div>
      <div className="login__wrapper">
        <div className="login__form">
          <Box m="20px">
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": {
                    gridColumn: isNonMobile ? undefined : "span 4",
                  },
                }}
              >
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Email"
                  {...register("email", { required: true })}
                  error={!!errors.email}
                  helperText={errors.email ? "required" : ""}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="password"
                  label="Password"
                  {...register("password", { required: true })}
                  error={!!errors.password}
                  helperText={errors.password ? "required" : ""}
                  sx={{ gridColumn: "span 4" }}
                />
              </Box>
              {loginError && (
                <Box color="red" textAlign="center" mt={2}>
                  Email və ya şifrə düzgün deyil
                </Box>
              )}
              <Box display="flex" justifyContent="end" mt="20px">
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  disabled={isSubmitting}
                >
                  Giriş
                </Button>
              </Box>
            </form>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default Login;
