import React, { useState, useEffect } from "react";
import { Box, Button, TextField, useTheme } from "@mui/material";
import { tokens } from "../theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../components/Header";
import { useDropzone } from "react-dropzone";
import StickyHeader from "../components/StickyHeader";
import SuccessModal from "../components/SuccessModal";
import ErrorModal from "../components/ErrorModal";
import { handleUnauthorizedResponse } from "../functions/tokenService";
import { useRequireToken } from "../functions/authUtils";
import useCheckTokenExpiration from "../functions/CheckTokenExpiration";
import apiToken from "../functions/tokenUtils";
import MyQuillEditor from "../components/MyQuillEditor";
import setPageTitle from "../functions/setTitle";
import * as consts from "../consts/Consts";

function AboutUs() {
  const title = setPageTitle("about");
  document.title = title;
  useRequireToken();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentLanguage, setCurrentLanguage] = useState("az");
  const [contactData, setContactData] = useState({});
  const [loading, setLoading] = useState(true);
  const [inputData, setInputData] = useState({});
  const [imageUrl, setImageUrl] = useState("");
  const [pageLoaded, setPageLoaded] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageChanged, setImageChanged] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const apiUrl = `${consts.API_URL}/about/edit`;
  const apiUrlPut = `${consts.API_URL}/about`;
  const apiUrlUploader = `${consts.API_URL}/uploader`;

  const checkTokenExpiration = useCheckTokenExpiration();

  useEffect(() => {
    checkTokenExpiration();
  }, [checkTokenExpiration]);

  const handleLanguageChange = (event, newValue) => {
    setCurrentLanguage(newValue);

    if (pageLoaded) {
      setInputData((prevInputData) => {
        const currentInputData = { ...prevInputData };
        return currentInputData;
      });
      if (isImageChanged) {
        handleImageDrop([selectedImage]);
      }
    } else {
      fetchDataForLanguage(newValue);
    }
  };

  const fetchDataForLanguage = (language) => {
    const headers = {
      Authorization: `Bearer ${apiToken()}`,
      "Accept-Language": language,
      "Content-Type": "application/json",
    };

    fetch(apiUrl, {
      headers,
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            handleUnauthorizedResponse(response);
          } else {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
        }
        return response.json();
      })
      .then((data) => {
        if (data && data.data) {
          setContactData(data.data);
          setLoading(false);

          if (pageLoaded) {
            const currentInputData = { ...inputData };

            setInputData(currentInputData);
          } else {
            const updatedInputData = {};
            const languages = ["az", "ru", "en"];

            languages.forEach((lang) => {
              updatedInputData[lang] = {
                text:
                  data.data.translations.find((t) => t.lang_code === lang)
                    ?.text || "",
                short_text:
                  data.data.translations.find((t) => t.lang_code === lang)
                    ?.short_text || "",
              };
            });

            setInputData(updatedInputData);
            setPageLoaded(true);
          }

          setImageUrl(data.data.image);
        } else {
          //  console.error("Получены некорректные данные:", data);
        }
      })
      .catch((error) => {
        //   console.error("Ошибка при получении данных:", error);
        setLoading(false);
      });

    if (isImageChanged) {
      const selectedImageFile = selectedImage;

      const formData = new FormData();
      formData.append("file", selectedImageFile, selectedImageFile.name);
      formData.append("path", "contact");

      fetch(apiUrlUploader, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${apiToken()}`,
          "Accept-Language": language,
          Accept: "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          //  console.log("Успешно загружено:", data);
          const uploadedImageUrl = data.data.file;
          //  console.log("URL изображения:", uploadedImageUrl);

          setImageUrl(uploadedImageUrl);
        })
        .catch((error) => {
          //  console.error("Ошибка при загрузке файла:", error);
        });
    }
  };

  useEffect(() => {
    fetchDataForLanguage(currentLanguage);
  }, [currentLanguage]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setInputData((prevTranslations) => {
      const currentTranslations = { ...prevTranslations };
      currentTranslations[currentLanguage] = {
        ...currentTranslations[currentLanguage],
        [name]: value,
      };
      return currentTranslations;
    });
  };

  const handleImageDrop = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const selectedImageFile = acceptedFiles[0];
      if (selectedImageFile.size > 1024 * 1024) {
        setErrorMessage("Fayl ölçüsü 1 MB-dan böyük olmamalıdır.");
        return;
      }
      setErrorMessage("");
      const formData = new FormData();
      formData.append("file", selectedImageFile, selectedImageFile.name);
      formData.append("path", "about");

      fetch(apiUrlUploader, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${apiToken()}`,
          "Accept-Language": currentLanguage,
          Accept: "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          //  console.log("Успешно загружено:", data);
          const uploadedImageUrl = data.data.file;

          setImageUrl(uploadedImageUrl);
          setSelectedImage(selectedImageFile);
          setImageChanged(true);
        })
        .catch((error) => {
          //  console.error("Ошибка при загрузке файла:", error);
        });
    }
  };

  const handleImageRemove = (e) => {
    e.preventDefault();
    setImageUrl("");
    setSelectedImage(null);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const isValid = validateForm();
    if (!isValid) {
      return;
    }

    const updatedData = {
      translations: [],
    };

    const languages = ["az", "ru", "en"];
    languages.forEach((lang) => {
      updatedData.translations.push({
        lang_code: lang,
        text: inputData[lang]?.text || "",
        short_text: inputData[lang]?.short_text || "",
      });
    });

    if (isImageChanged) {
      updatedData.image = imageUrl;
    }

    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${apiToken()}`,
        "Accept-Language": currentLanguage,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedData),
    };

    fetch(apiUrlPut, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setSuccessMessage("Forma uğurla göndərildi!");
      })
      .catch((error) => {
        setErrorMessage("Xəta baş verdi");
      });
  };

  const validateForm = () => {
    if (
      !inputData.az?.text ||
      !inputData.en?.text ||
      !inputData.ru?.text ||
      !inputData.az?.short_text ||
      !inputData.en?.short_text ||
      !inputData.ru?.short_text ||
      imageUrl === null
    ) {
      setErrorMessage(" Məlumatlar bütün dillərdə doldurulmalıdır.");
      setIsFormSubmitted(true);

      return false;
    }

    setErrorMessage("");
    return true;
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleImageDrop,
    accept: "image/*",
    multiple: false,
  });
  return (
    <>
      <SuccessModal
        successMessage={successMessage}
        onClose={() => setSuccessMessage("")}
      />
      <ErrorModal
        errorMessage={errorMessage}
        onClose={() => setErrorMessage("")}
      />
      <Box m="20px">
        <Header title="Haqqımızda" subtitle="Haqqımızda səhifəyə düzəliş et" />
        {loading ? (
          <p>Загрузка...</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <StickyHeader
              currentLanguage={currentLanguage}
              handleLanguageChange={handleLanguageChange}
            />
            <Box
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": {
                  gridColumn: isNonMobile ? undefined : "span 4",
                  width: "100%",
                  marginBottom: "20px",
                },
                "& .MuiBox-root": {
                  display: "flex",
                  flexWrap: "wrap",
                },
                "& > div.Mui-focused": {
                  outline: "1 !important",
                  borderColor: colors.grey[100],
                },
                "& label.Mui-focused ": {
                  color: colors.grey[100],
                },
                "& label.Mui-focused ": {
                  color: colors.grey[100],
                },
              }}
            >
              <MyQuillEditor
                keyProp={currentLanguage}
                name="text"
                value={inputData[currentLanguage]?.text || ""}
                onChange={(value) =>
                  handleInputChange({ target: { name: "text", value } })
                }
                label="Mətn"
              />
              <TextField
                label="Qısa mətn"
                name="short_text"
                value={inputData[currentLanguage]?.short_text || ""}
                onChange={handleInputChange}
                multiline
                rows={4}
                fullWidth
                error={
                  !inputData[currentLanguage]?.short_text && isFormSubmitted
                }
              />
              <div
                {...getRootProps()}
                style={{
                  ...dropzoneStyles,
                  border:
                    !imageUrl && isFormSubmitted
                      ? "1px solid red"
                      : "2px dashed #ccc",
                }}
              >
                <input {...getInputProps()} />
                {selectedImage ? (
                  <>
                    <p>Выбрано изображение: {selectedImage.name}</p>
                    <img
                      src={URL.createObjectURL(selectedImage)}
                      alt="Выбранное изображение"
                      style={{ maxWidth: "100%", maxHeight: "200px" }}
                    />
                    <button onClick={handleImageRemove}>Şəkili deyiş</button>
                  </>
                ) : (
                  <>
                    <img
                      src={contactData.image}
                      alt="Выбранное изображение"
                      style={{ maxWidth: "100%", maxHeight: "200px" }}
                    />
                    <button onClick={handleImageRemove}>Şəkili deyiş</button>
                  </>
                )}
              </div>
            </Box>
          </form>
        )}
      </Box>
    </>
  );
}

export default AboutUs;

const dropzoneStyles = {
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};
