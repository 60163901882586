import React from "react";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { Box, useTheme } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

function ErrorModal({ errorMessage, onClose }) {
  return (
    <Modal
      open={Boolean(errorMessage)}
      onClose={onClose}
      aria-labelledby="error-modal-title"
      aria-describedby="error-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "white",
          display: "block !important",
          borderRadius: "10px",
          padding: "30px !important",
          textAlign: "center !important",
          color: "black",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: "20px",
        }}
      >
        <ErrorIcon sx={{ fontSize: 80, color: "#FF0000" }} />
        <h2 id="error-modal-title">Xəta</h2>
        <p id="error-modal-description" sx={{ fontSize: "16px" }}>
          {errorMessage}
        </p>
        <Button
          onClick={onClose}
          sx={{
            fontSize: "16px",
            fontWeight: "600",
          }}
        >
          Bağla
        </Button>
      </Box>
    </Modal>
  );
}

export default ErrorModal;
