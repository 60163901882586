import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { Box, useTheme } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function SuccessModal({ successMessage, onClose }) {
  const handleGoBack = () => {
    onClose(); // Закрыть модальное окно
    window.history.back(); // Вернуться на предыдущую страницу
  };

  return (
    <Modal
      open={Boolean(successMessage)}
      onClose={onClose}
      aria-labelledby="success-modal-title"
      aria-describedby="success-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "white",
          display: "block !important",
          borderRadius: "10px",
          padding: "30px !important",
          textAlign: "center !important",
          color: "black",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: "20px",
        }}
      >
        <CheckCircleIcon sx={{ fontSize: 80, color: "#4CAF50" }} />{" "}
        <h2 id="success-modal-title">Uğur</h2>
        <p id="error-modal-description" sx={{ fontSize: "16px" }}>
          {successMessage}
        </p>
        <Button
          onClick={handleGoBack} // Используем новую функцию для перехода назад
          sx={{
            fontSize: "16px",
            fontWeight: "600",
          }}
        >
          Bağla
        </Button>
      </Box>
    </Modal>
  );
}

export default SuccessModal;
