import { useNavigate } from "react-router-dom";

const useNavigation = () => useNavigate();

const setToken = (tokenData) => {
  localStorage.setItem("token", JSON.stringify(tokenData));
};

const getToken = () => {
  const tokenDataString = localStorage.getItem("token");
  try {
    const tokenData = JSON.parse(tokenDataString);
    return tokenData ? tokenData : null;
  } catch (error) {
    return null;
  }
};

// const saveToken = (token) => {
//   setToken({
//     value: token,
//     expiration: new Date(new Date().getTime() + 20 * 60 * 1000),
//   });
// };

const saveToken = (token) => {
  const expiration = new Date();
  expiration.setHours(expiration.getHours() + 1);

  setToken({
    value: token,
    expiration: expiration.toISOString(),
  });
};

const handleUnauthorizedResponse = (navigate) => {
  navigate("/login");
};

const checkTokenAndNavigate = () => {
  const token = getToken();
  if (!token) {
    handleUnauthorizedResponse();
  }
};

const clearToken = () => {
  localStorage.removeItem("token");
};

export {
  saveToken,
  getToken,
  clearToken,
  //   apiTokenString,
  //   apiToken,
  handleUnauthorizedResponse,
  useNavigation,
  checkTokenAndNavigate,
};
