import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  useTheme,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
} from "@mui/material";
import { tokens } from "../theme";
import { useParams } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../components/Header";
import StickyHeader from "../components/StickyHeader";
import SuccessModal from "../components/SuccessModal";
import ErrorModal from "../components/ErrorModal";
import { createBrowserHistory } from "history";
import { handleUnauthorizedResponse } from "../functions/tokenService";
import { useRequireToken } from "../functions/authUtils";
import useCheckTokenExpiration from "../functions/CheckTokenExpiration";
import apiToken from "../functions/tokenUtils";
import * as consts from "../consts/Consts";


function CategoryAdd() {
  useRequireToken();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentLanguage, setCurrentLanguage] = useState("az");
  const [inputData, setInputData] = useState({
    az: { name: "" },
    en: { name: "" },
    ru: { name: "" },
  });
  const [loading, setLoading] = useState(true);

  const [activeStatus, setActiveStatus] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const [category, setCategory] = useState({});
  const [activeCategory, setActiveCategory] = useState("");

  const [selectedCategory, setSelectedCategory] = useState(activeCategory);
  const [selectedCategoryID, setSelectedCategoryID] = useState(null);
  const [activeCategoryID, setActiveCategoryID] = useState(null);
  const [subCategoryName, setSubCategoryName] = useState("");

  const params = useParams();
  const { itemId } = params;

  const history = createBrowserHistory();
  const location = history.location;
  const searchParams = new URLSearchParams(location.search);
  const newTypeID = searchParams.get("newTypeID");

  const [typeName, setTypeName] = useState("");

  useEffect(() => {
    switch (newTypeID) {
      case "1":
        setTypeName("product");
        break;
      case "2":
        setTypeName("solution");
        break;
      case "3":
        setTypeName("project");
        break;
      case "4":
        setTypeName("service");
        break;
      default:
        setTypeName("");
        break;
    }
  }, [newTypeID]);

  const apiUrlPost = `${consts.API_URL}/category/${typeName}`;
  const apiCategoryGet = `${consts.API_URL_SITE}/category/${typeName}`;

  const checkTokenExpiration = useCheckTokenExpiration();

  useEffect(() => {
    checkTokenExpiration();
  }, [checkTokenExpiration]);

  useEffect(() => {
    if (typeName !== "") {
      setLoading(false);
      fetchDataForCategory(currentLanguage);
    }
  }, [typeName]);

  const handleCategoryChange = (event) => {
    const selectedCategoryName = event.target.value;
    if (selectedCategoryName === "null") {
      setActiveCategory(null);
      setActiveCategoryID(null);
    } else {
      const selectedCategoryItem = category.find(
        (item) => item.name === selectedCategoryName
      );
      const selectedCategoryID = selectedCategoryItem.id;
      setActiveCategory(selectedCategoryName);
      setActiveCategoryID(selectedCategoryID);
    }
  };

  useEffect(() => {
    for (const key in category) {
      if (category && category.hasOwnProperty(key)) {
        const categoryItem = category[key];
        if (categoryItem && categoryItem.id === activeCategoryID) {
          setActiveCategory(categoryItem.name);
          break;
        }
      }
    }
  }, [category, activeCategoryID]);

  const fetchDataForCategory = (language) => {
    const headers = {
      Authorization: `Bearer ${apiToken()}`,
      "Accept-Language": language,
      "Content-Type": "application/json",
    };

    fetch(apiCategoryGet, {
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setCategory(data);
        setLoading(false);
      })
      .catch((error) => {
        //   console.error("Ошибка при получении данных:", error);
        setLoading(false);
      });
  };

  const handleStatusChange = (event) => {
    const selectedStatus = event.target.value;
    setActiveStatus(selectedStatus);
  };

  const handleLanguageChange = (event, newValue) => {
    setCurrentLanguage(newValue);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setInputData((prevInputData) => ({
      ...prevInputData,
      [currentLanguage]: {
        ...prevInputData[currentLanguage],
        [name]: value,
      },
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const isValid = validateForm();
    if (!isValid) {
      return;
    }

    try {
      const requestData = {
        parent_id: selectedCategoryID ? selectedCategoryID : activeCategoryID,
        status: activeStatus,
        translations: [
          {
            lang_code: "az",
            name: inputData["az"]?.name || "",
          },
          {
            lang_code: "en",
            name: inputData["en"]?.name || "",
          },
          {
            lang_code: "ru",
            name: inputData["ru"]?.name || "",
          },
        ],
      };

      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${apiToken()}`,
          "Accept-Language": currentLanguage,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      };

      const response = await fetch(apiUrlPost, requestOptions);

      if (!response.ok) {
        if (response.status === 401) {
          handleUnauthorizedResponse(response);
        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      }

      const data = await response.json();
      setSuccessMessage("Forma uğurla göndərildi!");
      setIsFormSubmitted(false);

      setInputData({
        az: { name: "" },
        en: { name: "" },
        ru: { name: "" },
      });
    } catch (error) {
      setErrorMessage("Xəta baş verdi");
    }
  };

  const validateForm = () => {
    if (
      !inputData.az?.name ||
      !inputData.en?.name ||
      !inputData.ru?.name ||
      activeStatus === null ||
      activeStatus === undefined
    ) {
      setErrorMessage(" Məlumatlar bütün dillərdə doldurulmalıdır.");
      return false;
    }

    setErrorMessage("");
    return true;
  };

  return (
    <>
      <SuccessModal
        successMessage={successMessage}
        onClose={() => setSuccessMessage("")}
      />
      <ErrorModal
        errorMessage={errorMessage}
        onClose={() => setErrorMessage("")}
      />
      <Box m="20px">
        <Header title="Kateqoriya" subtitle="Kateqoriya əlavə et" />
        {loading ? (
          <p>Загрузка...</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <Box
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": {
                  gridColumn: isNonMobile ? undefined : "span 4",
                  width: "100%",
                  marginBottom: "20px",
                },
                "& .MuiBox-root": {
                  display: "flex",
                  flexWrap: "wrap",
                },
                "& > div.Mui-focused": {
                  outline: "1 !important",
                  borderColor: colors.grey[100],
                },
                "& label.Mui-focused ": {
                  color: colors.grey[100],
                },
                "& label.Mui-focused ": {
                  color: colors.grey[100],
                },
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={activeStatus}
                  onChange={handleStatusChange}
                >
                  <MenuItem value={1}>Aktiv</MenuItem>
                  <MenuItem value={0}>Deaktiv</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Kateqoriya
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={activeCategory}
                  onChange={handleCategoryChange}
                >
                  <MenuItem value="null">Null</MenuItem>
                  {Object.keys(category).map((lang) => (
                    <MenuItem key={lang} value={category[lang].name}>
                      {category[lang].name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <StickyHeader
              currentLanguage={currentLanguage}
              handleLanguageChange={handleLanguageChange}
            />
            <Box
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": {
                  gridColumn: isNonMobile ? undefined : "span 4",
                  width: "100%",
                  marginBottom: "20px",
                },
                "& .MuiBox-root": {
                  display: "flex",
                  flexWrap: "wrap",
                },
                "& > div.Mui-focused": {
                  outline: "1 !important",
                  borderColor: colors.grey[100],
                },
                "& label.Mui-focused ": {
                  color: colors.grey[100],
                },
                "& label.Mui-focused ": {
                  color: colors.grey[100],
                },
              }}
            >
              <TextField
                label="Ad"
                name="name"
                value={inputData[currentLanguage]?.name || ""}
                onChange={handleInputChange}
                multiline
                fullWidth
                error={!inputData[currentLanguage]?.name && isFormSubmitted}
              />
            </Box>
          </form>
        )}
      </Box>
    </>
  );
}

export default CategoryAdd;
