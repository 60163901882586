import "./App.css";
import { useState } from "react";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route, useParams, useLocation } from "react-router-dom";
import Topbar from "./pages/global/Topbar";
import Sidebarr from "./pages/global/Sidebarr";
import Dashboard from "./pages/Dashboard";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import News from "./pages/News";
import AddArticle from "./pages/AddArticle";
import EditArticle from "./pages/EditArticle";
import Login from "./pages/Login";
import Social from "./pages/Social";
import CategoryProduct from "./pages/CategoryProduct";
import CategoryProductEdit from "./pages/CategoryProductEdit";
import Product from "./pages/Product";
import ProductEdit from "./pages/ProductEdit";
import CategoryProject from "./pages/CategoryProject";
import CategoryProjectAdd from "./pages/CategoryProjectAdd";
import Project from "./pages/Project";
import ProductAdd from "./pages/ProductAdd";
import ProjectAdd from "./pages/ProjectAdd";
import CategoryProjectEdit from "./pages/CategoryProjectEdit";
import CategorySolution from "./pages/CategorySolution";
import CategorySolutionEdit from "./pages/CategorySolutionEdit";
import CategorySolutionAdd from "./pages/CategorySolutionAdd";
import CategoryProductAdd from "./pages/CategoryProductAdd";
import CategoryService from "./pages/CategoryService";
import CategoryServiceAdd from "./pages/CategoryServiceAdd";
import CategoryServiceEdit from "./pages/CategoryServceiEdit";
import Solution from "./pages/Solution";
import SolutionAdd from "./pages/SolutionAdd";
import SolutionEdit from "./pages/SolutionEdit";
import ProjectEdit from "./pages/ProjectEdit";
import Service from "./pages/Service";
import ServiceAdd from "./pages/ServiceAdd";
import ServiceEdit from "./pages/ServiceEdit";
import Slider from "./pages/Slider";
import SliderEdit from "./pages/SliderEdit";
import SliderAdd from "./pages/SliderAdd";
import SubCategoryProduct from "./pages/SubCategoryProduct";
import SubCategoryProject from "./pages/SubCategoryProject";
import SubCategorySolution from "./pages/SubCategorySolution";
import SubCategoryService from "./pages/SubCategoryService";
import SubCategoryProductEdit from "./pages/SubCategoryProductEdit";


function App() {
  const [theme, colorMode] = useMode();
  const params = useParams();
  const { itemId } = params;
  const [isSidebar, setIsSidebar] = useState(true);
  const { pathname } = useLocation();
  const isLoginPage = pathname === "/" || pathname === "/login";
  const { typeID } = useParams();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {isLoginPage ? null : (
            <Sidebarr isSidebar={isSidebar} typeID={typeID} />
          )}
          <main className="content">
            {isLoginPage ? null : <Topbar isSidebar={isSidebar} />}
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/slider" element={<Slider />} />
              <Route path="/slider/edit/:itemId" element={<SliderEdit />} />
              <Route path="/slider/add" element={<SliderAdd />} />
              <Route path="/contact" element={<ContactUs />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/news" element={<News />} />
              <Route path="/news/add-article" element={<AddArticle />} />
              <Route path="/edit/:itemId" element={<EditArticle />} />
              <Route path="/" element={<Login />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/login" element={<Login />} />
              <Route path="/social" element={<Social />} />
              <Route
                path="/category-product/:newTypeID"
                element={<CategoryProduct />}
              />
              <Route path="/category-product" element={<CategoryProduct />} />
              <Route
                path="/edit-category/:itemId"
                element={<CategoryProductEdit />}
              />
              <Route
                path="/edit-category/:itemId/:newTypeID"
                element={<CategoryProductEdit />}
              />
              <Route path="/add-category" element={<CategoryProductAdd />} />
              <Route
                path="/add-category:newTypeID"
                element={<CategoryProductAdd />}
              />

              <Route path="/product" element={<Product />} />
              <Route path="/product/edit/:itemId" element={<ProductEdit />} />
              <Route path="/product/add" element={<ProductAdd />} />
              <Route
                path="/category-project/:newTypeID"
                element={<CategoryProject />}
              />
              <Route path="/category-project" element={<CategoryProject />} />
              <Route
                path="/add-category:newTypeI"
                element={<CategoryProjectAdd />}
              />
              <Route path="/product/edit/:itemId" element={<ProductEdit />} />
              <Route path="/project/add" element={<ProjectAdd />} />
              <Route
                path="/edit-category/:itemId"
                element={<CategoryProjectEdit />}
              />
              <Route
                path="/category-solution/:newTypeID"
                element={<CategorySolution />}
              />
              <Route path="/category-solution" element={<CategorySolution />} />
              <Route
                path="/edit-category/:itemId"
                element={<CategorySolutionEdit />}
              />
              <Route
                path="add-category:newTypeI"
                element={<CategorySolutionAdd />}
              />
              <Route
                path="/category-service/:newTypeID"
                element={<CategoryService />}
              />
              <Route path="/category-service" element={<CategoryService />} />
              <Route
                path="/add-category:newTypeI"
                element={<CategoryServiceAdd />}
              />
              <Route
                path="/edit-category/:itemId"
                element={<CategoryServiceEdit />}
              />
              <Route path="/solution" element={<Solution />} />
              <Route path="/solution/add" element={<SolutionAdd />} />
              <Route path="/solution/edit/:itemId" element={<SolutionEdit />} />
              <Route path="/project" element={<Project />} />
              <Route path="/project/add" element={<ProjectAdd />} />
              <Route path="/project/edit/:itemId" element={<ProjectEdit />} />
              <Route path="/service" element={<Service />} />
              <Route path="/service/add" element={<ServiceAdd />} />
              <Route path="/service/edit/:itemId" element={<ServiceEdit />} />
              <Route
                path="/subcategory-product/:itemId"
                element={<SubCategoryProduct />}
              />
              <Route
                path="/subcategory-project/:itemId"
                element={<SubCategoryProject />}
              />
              <Route
                path="/subcategory-solution/:itemId"
                element={<SubCategorySolution />}
              />
              <Route
                path="/subcategory-service/:itemId"
                element={<SubCategoryService />}
              />
              <Route
                path="/edit-subcategory/:itemId"
                element={<SubCategoryProductEdit />}
              />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
