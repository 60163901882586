import React from "react";
import CategoryAdd from "../components/CategoryAdd";
import setPageTitle from "../functions/setTitle";

function CategorySolutionAdd() {
  const title = setPageTitle("category");
  document.title = title;
  return <CategoryAdd />;
}

export default CategorySolutionAdd;
