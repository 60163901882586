import React from "react";
import { Box, Button, useTheme } from "@mui/material";
import TabsLanguage from "./TabsLanguage";
import { tokens } from "../theme";

function StickyHeader(props) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { currentLanguage, handleLanguageChange } = props;

  return (
    <Box
      display="flex"
      mt="20px"
      mb="20px"
      position="sticky"
      top="0"
      zIndex="100"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        backgroundColor:
          theme.palette.mode === "dark" ? colors.primary[500] : "#fcfcfc",
        "& button": {
          height: "50px",
        },
      }}
    >
      <TabsLanguage
        currentLanguage={currentLanguage}
        onLanguageChange={handleLanguageChange}
      />
      <Button
        type="submit"
        color="secondary"
        variant="contained"
        height="60px !important"
      >
        Düzəliş et
      </Button>
    </Box>
  );
}

export default StickyHeader;
