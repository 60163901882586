import React from "react";
import Category from "../components/Category";
import setPageTitle from "../functions/setTitle";

function CategoryService() {
  const title = setPageTitle("category-service");
  document.title = title;
  return <Category />;
}

export default CategoryService;
