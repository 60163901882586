import { useNavigate } from "react-router-dom";
import {
  getToken,
  handleUnauthorizedResponse,
  clearToken,
} from "./tokenService";

const useCheckTokenExpiration = () => {
  const navigate = useNavigate();

  const checkTokenExpiration = () => {
    const token = getToken();
    if (token) {
      const expirationTime = new Date(token.expiration);
      const currentTime = new Date();
      if (currentTime > expirationTime) {
        handleUnauthorizedResponse(navigate);
        clearToken();
      }
    }
  };

  return checkTokenExpiration;
};

export default useCheckTokenExpiration;
