import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  useTheme,
} from "@mui/material";
import { tokens } from "../theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../components/Header";
import { useDropzone } from "react-dropzone";
import StickyHeader from "../components/StickyHeader";
import SuccessModal from "../components/SuccessModal";
import ErrorModal from "../components/ErrorModal";
import { handleUnauthorizedResponse } from "../functions/tokenService";
import { useRequireToken } from "../functions/authUtils";
import useCheckTokenExpiration from "../functions/CheckTokenExpiration";
import apiToken from "../functions/tokenUtils";
import MyQuillEditor from "../components/MyQuillEditor";
import setPageTitle from "../functions/setTitle";
import * as consts from "../consts/Consts";

function AddArticle() {
  const title = setPageTitle("news");
  document.title = title;
  useRequireToken();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentLanguage, setCurrentLanguage] = useState("az");
  const [getData, setgetData] = useState({});
  const [inputData, setInputData] = useState({});
  const [imageUrl, setImageUrl] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageChanged, setImageChanged] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const apiUrlPost = `${consts.API_URL}/news`;
  const apiUrlUploader = `${consts.API_URL}/uploader`;

  const checkTokenExpiration = useCheckTokenExpiration();

  useEffect(() => {
    checkTokenExpiration();
  }, [checkTokenExpiration]);

  const [activeStatus, setActiveStatus] = useState(1);
  const [status, setStatus] = useState(activeStatus);

  const handleStatusChange = (event) => {
    const selectedStatus = event.target.value;
    setActiveStatus(selectedStatus);
    setStatus(selectedStatus);
  };

  const handleLanguageChange = (event, newValue) => {
    setCurrentLanguage(newValue);

    if (isImageChanged) {
      handleImageDrop([selectedImage]);
    }

    setInputData((prevInputData) => {
      const updatedInputData = { ...prevInputData };

      if (!updatedInputData[newValue]) {
        updatedInputData[newValue] = {};
      }

      updatedInputData[newValue].status = updatedInputData.az?.status || "";

      return updatedInputData;
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setInputData((prevTranslations) => {
      const currentTranslations = { ...prevTranslations };
      currentTranslations[currentLanguage] = {
        ...currentTranslations[currentLanguage],
        [name]: value,
      };
      return currentTranslations;
    });
  };

  const handleImageDrop = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const selectedImageFile = acceptedFiles[0];
      if (selectedImageFile.size > 1024 * 1024) {
        setErrorMessage("Fayl ölçüsü 1 MB-dan böyük olmamalıdır.");
        return;
      }
      setErrorMessage("");
      const formData = new FormData();
      formData.append("file", selectedImageFile, selectedImageFile.name);
      formData.append("path", "news");

      fetch(apiUrlUploader, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${apiToken()}`,
          "Accept-Language": currentLanguage,
          Accept: "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            if (response.status === 401) {
              handleUnauthorizedResponse(response);
            } else {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
          }
          return response.json();
        })
        .then((data) => {
          const uploadedImageUrl = data.data.file;
          setImageUrl(uploadedImageUrl);
          setSelectedImage(selectedImageFile);
          setImageChanged(true);
        })
        .catch((error) => {});
    } else {
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const isValid = validateForm();
    if (!isValid) {
      return;
    }

    let isValidURL = true;

    const languages = ["az", "ru", "en"];

    if (!isValidURL) {
      return;
    }

    if (!isImageChanged) {
      setErrorMessage("Şəkil əlavə edilməyib. Şəkil əlavə edilməlidir.");
      setIsFormSubmitted(true);
      return;
    }

    setErrorMessage("");

    const updatedData = {
      status: activeStatus,
      image: imageUrl,
      translations: [],
    };

    languages.forEach((lang) => {
      updatedData.translations.push({
        lang_code: lang,
        title: inputData[lang]?.title || "",
        desc: inputData[lang]?.desc || "",
      });
    });

    if (!isImageChanged) {
      delete updatedData.image;
    } else {
      updatedData.image = imageUrl;
    }
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${apiToken()}`,
        "Accept-Language": currentLanguage,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedData),
    };

    try {
      const response = await fetch(apiUrlPost, requestOptions);

      if (!response.ok) {
        if (response.status === 401) {
          handleUnauthorizedResponse(response);
        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      }

      const data = await response.json();
      setSuccessMessage("Forma uğurla göndərildi!");
      setIsFormSubmitted(false);
      setSelectedImage(null);

      setInputData({
        az: { name: "" },
        en: { name: "" },
        ru: { name: "" },
      });
    } catch (error) {}
  };

  const validateForm = () => {
    if (
      !inputData.az?.title ||
      !inputData.en?.title ||
      !inputData.ru?.title ||
      !inputData.az?.desc ||
      !inputData.en?.desc ||
      !inputData.ru?.desc ||
      imageUrl === null ||
      activeStatus === null ||
      activeStatus === undefined
    ) {
      setErrorMessage(" Məlumatlar bütün dillərdə doldurulmalıdır.");
      setIsFormSubmitted(true);

      return false;
    }

    setErrorMessage("");
    return true;
  };

  const handleImageRemove = (e) => {
    e.preventDefault();
    setImageUrl("");
    setSelectedImage(null);
  };

  const { getRootProps: getImageRootProps, getInputProps: getImageInputProps } =
    useDropzone({
      onDrop: handleImageDrop,
      accept: "image/*",
      multiple: false,
    });

  return (
    <>
      <SuccessModal
        successMessage={successMessage}
        onClose={() => setSuccessMessage("")}
      />
      <ErrorModal
        errorMessage={errorMessage}
        onClose={() => setErrorMessage("")}
      />

      <Box m="20px">
        <Header title="Xidmətlər" subtitle="Xidməti əlavə et" />
        <form onSubmit={handleSubmit}>
          <Box
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": {
                gridColumn: isNonMobile ? undefined : "span 4",
                width: "100%",
                marginBottom: "20px",
              },
              "& .MuiBox-root": {
                display: "flex",
                flexWrap: "wrap",
              },
              "& > div.Mui-focused": {
                outline: "1 !important",
                borderColor: colors.grey[100],
              },
              "& label.Mui-focused ": {
                color: colors.grey[100],
              },
              "& label.Mui-focused ": {
                color: colors.grey[100],
              },
            }}
          >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={activeStatus}
                onChange={handleStatusChange}
              >
                <MenuItem value={1}>Aktiv</MenuItem>
                <MenuItem value={0}>Deaktiv</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <StickyHeader
            currentLanguage={currentLanguage}
            handleLanguageChange={handleLanguageChange}
          />
          <Box
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": {
                gridColumn: isNonMobile ? undefined : "span 4",
                width: "100%",
                marginBottom: "20px",
              },
              "& .MuiBox-root": {
                display: "flex",
                flexWrap: "wrap",
              },
              "& > div.Mui-focused": {
                outline: "1 !important",
                borderColor: colors.grey[100],
              },
              "& label.Mui-focused ": {
                color: colors.grey[100],
              },
              "& label.Mui-focused ": {
                color: colors.grey[100],
              },
            }}
          >
            <TextField
              label="Başlıq"
              name="title"
              value={inputData[currentLanguage]?.title || ""}
              onChange={handleInputChange}
              error={!inputData[currentLanguage]?.title && isFormSubmitted}
            />
            <MyQuillEditor
              keyProp={currentLanguage}
              name="desc"
              value={inputData[currentLanguage]?.desc || ""}
              onChange={(value) =>
                handleInputChange({ target: { name: "desc", value } })
              }
              label="Təsvir"
            />
            <div
              {...getImageRootProps()}
              style={{
                ...dropzoneStyles,
                border:
                  !imageUrl && isFormSubmitted
                    ? "1px solid red"
                    : "2px dashed #ccc",
              }}
            >
              <input {...getImageInputProps()} />
              {selectedImage ? (
                <>
                  <p>Seçilmiş şəkil: {selectedImage.name}</p>
                  <img
                    src={URL.createObjectURL(selectedImage)}
                    alt="Seçilmiş şəkil"
                    style={{ maxWidth: "100%", maxHeight: "200px" }}
                  />
                  <button onClick={handleImageRemove}>Şəkili deyiş</button>
                </>
              ) : (
                <>
                  <img
                    src={getData.image}
                    alt="Seçilmiş şəkil"
                    style={{ maxWidth: "100%", maxHeight: "200px" }}
                  />
                  <button onClick={handleImageRemove}>Şəkili deyiş</button>
                </>
              )}
            </div>
          </Box>
        </form>
      </Box>
    </>
  );
}

export default AddArticle;

const dropzoneStyles = {
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};
const invalidFieldStyle = {
  border: "1px solid red",
};
