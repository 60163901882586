import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { tokens } from "../theme";
import {
  Box,
  TextField,
  useTheme,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../components/Header";
import StickyHeaderEdit from "../components/StickyHeaderEdit";
import SuccessModal from "../components/SuccessModal";
import ErrorModal from "../components/ErrorModal";
import { createBrowserHistory } from "history";
import DeleteConfirmationModal from "../components/DeleteConfirmationModal";
import { handleUnauthorizedResponse } from "../functions/tokenService";
import { useRequireToken } from "../functions/authUtils";
import useCheckTokenExpiration from "../functions/CheckTokenExpiration";
import apiToken from "../functions/tokenUtils";
import * as consts from "../consts/Consts";


function CategoryEdit() {
  useRequireToken();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentLanguage, setCurrentLanguage] = useState("az");
  const [contactData, setContactData] = useState({});
  const [loading, setLoading] = useState(true);
  const [inputData, setInputData] = useState({});
  const [pageLoaded, setPageLoaded] = useState(false);
  const [activeStatus, setActiveStatus] = useState("");
  const [status, setStatus] = useState(activeStatus);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  const params = useParams();
  const { itemId } = params;

  const history = createBrowserHistory();
  const location = history.location;
  const searchParams = new URLSearchParams(location.search);
  const newTypeID = searchParams.get("newTypeID");

  const [typeName, setTypeName] = useState("");

  useEffect(() => {
    switch (newTypeID) {
      case "1":
        setTypeName("product");
        break;
      case "2":
        setTypeName("solution");
        break;
      case "3":
        setTypeName("project");
        break;
      case "4":
        setTypeName("service");
        break;
      default:
        setTypeName("");
        break;
    }
  }, [newTypeID]);

  const apiUrl = `${consts.API_URL}/category/${typeName}/${itemId}/edit`;
  const apiUrlPut = `${consts.API_URL}/category/${typeName}/${itemId}`;

  const checkTokenExpiration = useCheckTokenExpiration();

  useEffect(() => {
    checkTokenExpiration();
  }, [checkTokenExpiration]);

  useEffect(() => {
    if (typeName !== "") {
      setLoading(false);
      fetchDataForLanguage(currentLanguage);
    }
  }, [typeName]);

  useEffect(() => {
    // Здесь можно использовать apiUrl и apiUrlPut
    console.log(apiUrl);
    console.log(apiUrlPut);
  }, [apiUrl, apiUrlPut]);

  const handleStatusChange = (event) => {
    const selectedStatus = event.target.value;
    setActiveStatus(selectedStatus);
    setStatus(selectedStatus);
  };

  const handleLanguageChange = (event, newValue) => {
    setCurrentLanguage(newValue);

    if (pageLoaded) {
      setInputData((prevInputData) => {
        const currentInputData = { ...prevInputData };
        return currentInputData;
      });
    } else {
      fetchDataForLanguage(newValue);
    }
  };

  const fetchDataForLanguage = (language) => {
    const headers = {
      Authorization: `Bearer ${apiToken()}`,
      "Accept-Language": language,
      "Content-Type": "application/json",
    };

    fetch(apiUrl, {
      headers,
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            handleUnauthorizedResponse(response);
          } else {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
        }
        return response.json();
      })
      .then((data) => {
        if (data && data.data) {
          setContactData(data.data);
          setLoading(false);

          const commonStatus = data.data.status;
          setActiveStatus(commonStatus);

          if (pageLoaded) {
            const currentInputData = { ...inputData };
            setInputData((prevInputData) => ({
              ...prevInputData,
              status: commonStatus,
            }));

            setInputData(currentInputData);
          } else {
            const updatedInputData = {};
            const languages = ["az", "ru", "en"];

            languages.forEach((lang) => {
              updatedInputData[lang] = {
                name:
                  data.data.translations.find((t) => t.lang_code === lang)
                    ?.name || "",
                status: commonStatus,
              };
            });

            setInputData(updatedInputData);
            setPageLoaded(true);
          }
        } else {
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(
    () => {
      fetchDataForLanguage(currentLanguage);
    },
    [currentLanguage],
    [typeName]
  );

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setInputData((prevTranslations) => {
      const currentTranslations = { ...prevTranslations };
      currentTranslations[currentLanguage] = {
        ...currentTranslations[currentLanguage],
        [name]: value,
      };
      return currentTranslations;
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const isValid = validateForm();
    if (!isValid) {
      return;
    }

    const updatedData = {
      status: activeStatus,
      translations: [],
    };

    const languages = ["az", "ru", "en"];
    languages.forEach((lang) => {
      updatedData.translations.push({
        lang_code: lang,
        name: inputData[lang]?.name || "",
      });
    });

    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${apiToken()}`,
        "Accept-Language": currentLanguage,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedData),
    };

    fetch(apiUrlPut, requestOptions)
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            handleUnauthorizedResponse(response);
          } else {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
        }
        return response.json();
      })
      .then((data) => {
        setSuccessMessage("Forma uğurla göndərildi!");
      })
      .catch((error) => {
        setErrorMessage("Xəta baş verdi");
      });
  };

  const validateForm = () => {
    if (
      !inputData.az?.name ||
      !inputData.en?.name ||
      !inputData.ru?.name ||
      activeStatus === null ||
      activeStatus === undefined
    ) {
      setErrorMessage(" Məlumatlar bütün dillərdə doldurulmalıdır.");
      setIsFormSubmitted(true);

      return false;
    }

    setErrorMessage("");
    return true;
  };

  const handleDeleteClick = (itemId) => {
    setSelectedItem(itemId);
    setDeleteModalOpen(true);
  };

  const handleDelete = async () => {
    setDeleteModalOpen(false);

    if (!selectedItem) {
      return;
    }

    const deleteUrl = `${consts.API_URL}/category/${typeName}/${itemId}`;

    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${apiToken()}`,
        "Accept-Language": currentLanguage,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(deleteUrl, requestOptions);

      if (!response.ok) {
        if (response.status === 401) {
          handleUnauthorizedResponse(response);
        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      }
      setSuccessMessage("Element uğurla silindi!");
      // console.log("Успешно удалено");

      // setgetData((prevgetData) =>
      //   prevgetData.filter((news) => news.id !== selectedItem.id)
      // );
    } catch (error) {
      // console.error("Ошибка при удалении данных:", error);
    }
  };

  console.log(selectedItem);
  return (
    <>
      <DeleteConfirmationModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={handleDelete}
      />
      <SuccessModal
        successMessage={successMessage}
        onClose={() => setSuccessMessage("")}
      />
      <ErrorModal
        errorMessage={errorMessage}
        onClose={() => setErrorMessage("")}
      />
      <Box m="20px">
        <Header title="Kateqoriya" subtitle="Kateqoriyaya düzəliş et" />

        {loading ? (
          <p>Загрузка...</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <Box
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": {
                  gridColumn: isNonMobile ? undefined : "span 4",
                  width: "100%",
                  marginBottom: "20px",
                },
                "& .MuiBox-root": {
                  display: "flex",
                  flexWrap: "wrap",
                },
                "& > div.Mui-focused": {
                  outline: "1 !important",
                  borderColor: colors.grey[100],
                },
                "& label.Mui-focused ": {
                  color: colors.grey[100],
                },
                "& label.Mui-focused ": {
                  color: colors.grey[100],
                },
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={activeStatus}
                  onChange={handleStatusChange}
                >
                  <MenuItem value={1}>Aktiv</MenuItem>
                  <MenuItem value={0}>Deaktiv</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <StickyHeaderEdit
              currentLanguage={currentLanguage}
              handleLanguageChange={handleLanguageChange}
              handleDeleteClick={handleDeleteClick}
              itemId={itemId}
            />
            <Box
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": {
                  gridColumn: isNonMobile ? undefined : "span 4",
                  width: "100%",
                  marginBottom: "20px",
                },
                "& .MuiBox-root": {
                  display: "flex",
                  flexWrap: "wrap",
                },
                "& > div.Mui-focused": {
                  outline: "1 !important",
                  borderColor: colors.grey[100],
                },
                "& label.Mui-focused ": {
                  color: colors.grey[100],
                },
                "& label.Mui-focused ": {
                  color: colors.grey[100],
                },
              }}
            >
              <TextField
                label="Ad"
                name="name"
                value={inputData[currentLanguage]?.name || ""}
                onChange={handleInputChange}
                multiline
                fullWidth
                error={!inputData[currentLanguage]?.name && isFormSubmitted}
              />
            </Box>
          </form>
        )}
      </Box>
    </>
  );
}

export default CategoryEdit;
