const setPageTitle = (page, detailsName) => {
  switch (page) {
    case "home":
      return "Ana səhifə";
    case "news":
      return "Xəbərlər";
    case "about":
      return "Haqqımızda";
    case "contact":
      return "Əlaqə";
    case "login":
      return "Login";
    case "products":
      return "Məhsullar";
    case "solutions":
      return "Həllər";
    case "services":
      return "Xidmətlər";
    case "projects":
      return "Layihələr";
    case "category":
      return "Kateqoriya";
    case "category-product":
      return "Məhsul kateqoriyası";
    case "category-solution":
      return "Həll kateqoriyası";
    case "category-service":
      return "Xidmət kateqoriyası";
    case "category-project":
      return "Layihə kateqoriyası";
    case "subcategory":
      return "Altkateqoriya";
    case "subcategory-product":
      return "Məhsul altkateqoriyası";
    case "subcategory-solution":
      return "Həll altkateqoriyası";
    case "subcategory-service":
      return "Xidmət altkateqoriyası";
    case "subcategory-project":
      return "Layihə altkateqoriyası";
    case "products":
      return "Məhsullar";
    case "solutions":
      return "Həlllər";
    case "services":
      return "Xidmətlər";
    case "projects":
      return "Layihələr";
    case "details":
      return detailsName;
    default:
      return "";
  }
};

export default setPageTitle;
