import React from "react";
import { Alert } from "@mui/material";

function SuccessAlert({ message, onClose }) {
  return (
    <Alert variant="outlined" severity="success" onClose={onClose}>
      {message}
    </Alert>
  );
}

export default SuccessAlert;
