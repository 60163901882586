import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";

function DeleteConfirmationModal({ open, onClose, onConfirm }) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiBox-root": {
          background: "#fff",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "block !important",
          borderRadius: "10px",
          padding: "30px !important",
          textAlign: "center !important",
        },
        " & .MuiTypography-root": {
          color: "black !important",
          fontSize: "18px !important",
          marginBottom: "20px !important",
        },
        "& button:first-of-type": {
          marginRight: "15px",
        },
      }}
    >
      <Box p={2}>
        <Typography>Seçdiyiniz elementi silmək istəyirsiniz ?</Typography>
        <Button variant="contained" color="error" onClick={onConfirm}>
          Bəli
        </Button>
        <Button variant="contained" onClick={onClose}>
          Xeyr
        </Button>
      </Box>
    </Modal>
  );
}

export default DeleteConfirmationModal;
