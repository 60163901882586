import React, { useRef } from "react";

function ImagesUpload({
  onDragOver,
  onDragLeave,
  onDrop,
  isDragging,
  selectFiles,
  fileInputRef,
  onFilesSelect,
  images,
  deleteImage,
  uploadImages,
}) {
  return (
    <div className="card">
      <div className="top">
        <p>Drag & Drop image uploading</p>
      </div>
      <div
        className="drag-area"
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        {isDragging ? (
          <span className="select">Şəkil əlavə et</span>
        ) : (
          <>
            Faylı bura sürükləyin və ya fayl seçmək üçün klikləyin.
            <span className="select" role="button" onClick={selectFiles}>
              Yüklə
            </span>
          </>
        )}

        <input
          type="file"
          name="file"
          id=""
          className="file"
          multiple
          ref={fileInputRef}
          onChange={onFilesSelect}
        />
      </div>
      <div className="container-images">
        {images.map((image, index) => (
          <div className="image" key={index}>
            <span className="delete" onClick={() => deleteImage(index)}>
              &times;
            </span>
            <img src={image.url} alt={image.name} />
          </div>
        ))}
      </div>
      <button type="button" onClick={uploadImages}>
        Yüklə
      </button>
    </div>
  );
}

export default ImagesUpload;
