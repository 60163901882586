import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Category from "../components/Category";
import setPageTitle from "../functions/setTitle";

function CategorySolution() {
  const title = setPageTitle("category-solution");
  document.title = title;
  const { typeID } = useParams();

  return (
    <>
      <Category />
    </>
  );
}

export default CategorySolution;
