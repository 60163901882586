import React from "react";
import { Box, Button, useTheme } from "@mui/material";
import TabsLanguage from "./TabsLanguage";
import { tokens } from "../theme";

function StickyHeaderEdit(props) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { currentLanguage, handleLanguageChange, handleDeleteClick, itemId } =
    props;

  return (
    <Box
      display="flex"
      mt="20px"
      mb="20px"
      position="sticky"
      top="0"
      zIndex="100"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        backgroundColor:
          theme.palette.mode === "dark" ? colors.primary[500] : "#fcfcfc",
        "& button": {
          height: "50px",
        },
      }}
    >
      <TabsLanguage
        currentLanguage={currentLanguage}
        onLanguageChange={handleLanguageChange}
      />
      <Box>
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          height="60px !important"
          sx={{
            marginRight: "20px",
          }}
        >
          Düzəliş et
        </Button>
        <Button
          color="error"
          variant="contained"
          height="60px !important"
          onClick={() => handleDeleteClick(itemId)}
        >
          Sil
        </Button>
      </Box>
    </Box>
  );
}

export default StickyHeaderEdit;
