import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  TextField,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  useTheme,
} from "@mui/material";
import { tokens } from "../theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../components/Header";
import { useDropzone } from "react-dropzone";
import StickyHeader from "../components/StickyHeader";
import SuccessModal from "../components/SuccessModal";
import ErrorModal from "../components/ErrorModal";
import ImagesUpload from "../components/ImagesUpload";
import { handleUnauthorizedResponse } from "../functions/tokenService";
import { useRequireToken } from "../functions/authUtils";
import useCheckTokenExpiration from "../functions/CheckTokenExpiration";
import apiToken from "../functions/tokenUtils";
import MyQuillEditor from "../components/MyQuillEditor";
import setPageTitle from "../functions/setTitle";
import * as consts from "../consts/Consts";

function ProductAdd() {
  const title = setPageTitle("products");
  document.title = title;
  useRequireToken();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentLanguage, setCurrentLanguage] = useState("az");
  const [getData, setgetData] = useState({});
  const [loading, setLoading] = useState(true);
  const [inputData, setInputData] = useState({});
  const [imageUrl, setImageUrl] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageChanged, setImageChanged] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const apiUrlPost = `${consts.API_URL}/product`;
  const apiUrlUploader = `${consts.API_URL}/uploader`;
  const apiCategoryGet = `${consts.API_URL_SITE}/category/product`;

  const checkTokenExpiration = useCheckTokenExpiration();

  useEffect(() => {
    checkTokenExpiration();
  }, [checkTokenExpiration]);

  const [category, setCategory] = useState({});
  const [selectedPdfs, setSelectedPdfs] = useState({});
  const [selectedPdfNames, setSelectedPdfNames] = useState({});
  const [activeStatus, setActiveStatus] = useState(1);
  const [status, setStatus] = useState(activeStatus);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCategoryID, setSelectedCategoryID] = useState(null);

  const [images, setImages] = useState([]);
  const [imagesUrls, setImagesUrls] = useState([]);

  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);
  const selectFiles = () => {
    fileInputRef.current.click();
  };

  const onFilesSelect = (event) => {
    const files = event.target.files;
    if (files.length === 0) return;
    for (let i = 0; i < files.length; i++) {
      if (files[i].type.split("/")[0] !== "image") continue;
      if (!images.some((e) => e.name === files[i].name)) {
        setImages((prevImages) => [
          ...prevImages,
          {
            name: files[i].name,
            url: URL.createObjectURL(files[i]),
            file: files[i],
          },
        ]);
      }
    }
  };

  const deleteImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const onDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
    event.dataTransfer.dropEffect = "copy";
  };

  const onDragLeave = (event) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const onDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const files = event.dataTransfer.files;
    for (let i = 0; i < files.length; i++) {
      if (files[i].type.split("/")[0] !== "image") continue;
      if (!images.some((e) => e.name === files[i].name)) {
        setImages((prevImages) => [
          ...prevImages,
          {
            name: files[i].name,
            url: URL.createObjectURL(files[i]),
            file: files[i],
          },
        ]);
      }
    }
  };

  const uploadImages = () => {
    const formData = new FormData();

    images.forEach((image, index) => {
      formData.append("file", image.file);
      formData.append("path", "product");

      fetch(apiUrlUploader, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${apiToken()}`,
          "Accept-Language": currentLanguage,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          const uploadedImageUrl = data.data.file;
          const newImage = { image: uploadedImageUrl };
          setImagesUrls((prevImagesUrls) => [...prevImagesUrls, newImage]);
        })
        .catch((error) => {});
    });
  };

  const handleStatusChange = (event) => {
    const selectedStatus = event.target.value;
    setActiveStatus(selectedStatus);
    setStatus(selectedStatus);
  };

  const handleCategoryChange = (event) => {
    const selectedCategoryName = event.target.value;
    const selectedCategoryItem = category.find(
      (item) => item.name === selectedCategoryName
    );
    const selectedCategoryID = selectedCategoryItem.id;
    setSelectedCategory(selectedCategoryName);
    setSelectedCategoryID(selectedCategoryID);
  };

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${apiToken()}`,
      "Accept-Language": "az",
      "Content-Type": "application/json",
    };

    fetch(apiCategoryGet, {
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data) && data.length > 0) {
          const categoryData = data.map((item) => ({
            ...item,
            sub: item.sub || [],
          }));

          const allSubCategories = categoryData.flatMap((item) => item.sub);
          setCategory(allSubCategories);
        } else {
          setCategory([]);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [apiToken, currentLanguage]);

  const handleLanguageChange = (event, newValue) => {
    setCurrentLanguage(newValue);

    if (isImageChanged) {
      handleImageDrop([selectedImage]);
    }

    setInputData((prevInputData) => {
      const updatedInputData = { ...prevInputData };

      if (!updatedInputData[newValue]) {
        updatedInputData[newValue] = {};
      }

      updatedInputData[newValue].price = updatedInputData.az?.price || "";
      updatedInputData[newValue].status = updatedInputData.az?.status || "";
      updatedInputData[newValue].order = updatedInputData.az?.order || "";

      return updatedInputData;
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setInputData((prevTranslations) => {
      const currentTranslations = { ...prevTranslations };
      currentTranslations[currentLanguage] = {
        ...currentTranslations[currentLanguage],
        [name]: value,
      };
      console.log(currentTranslations);
      return currentTranslations;
    });
  };

  const handleImageDrop = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const selectedImageFile = acceptedFiles[0];
      if (selectedImageFile.size > 1024 * 1024) {
        setErrorMessage("Fayl ölçüsü 1 MB-dan böyük olmamalıdır.");
        return;
      }
      setErrorMessage("");
      const formData = new FormData();
      formData.append("file", selectedImageFile, selectedImageFile.name);
      formData.append("path", "product");

      fetch(apiUrlUploader, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${apiToken()}`,
          "Accept-Language": currentLanguage,
          Accept: "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            if (response.status === 401) {
              handleUnauthorizedResponse(response);
            } else {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
          }
          return response.json();
        })
        .then((data) => {
          const uploadedImageUrl = data.data.file;

          setImageUrl(uploadedImageUrl);
          setSelectedImage(selectedImageFile);
          setImageChanged(true);
        })
        .catch((error) => {});
    } else {
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const isValid = validateForm();
    if (!isValid) {
      return;
    }

    const updatedData = {
      product_category_id: selectedCategoryID,
      price: +inputData[currentLanguage]?.price || 0,
      order: +inputData[currentLanguage]?.order || 0,
      status: activeStatus,
      translations: [],
      images: imagesUrls,
    };

    const languages = ["az", "ru", "en"];
    languages.forEach((lang) => {
      updatedData.translations.push({
        lang_code: lang,
        name: inputData[lang]?.name || "",
        desc: inputData[lang]?.desc || "",
        pdf: selectedPdfs[lang],
      });
    });
    if (!isImageChanged) {
      delete updatedData.image;
    } else {
      updatedData.image = imageUrl;
    }
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${apiToken()}`,
        "Accept-Language": currentLanguage,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedData),
    };

    try {
      const response = await fetch(apiUrlPost, requestOptions);

      if (!response.ok) {
        if (response.status === 401) {
          handleUnauthorizedResponse(response);
        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      }

      const data = await response.json();
      setSuccessMessage("Forma uğurla göndərildi!");
      setIsFormSubmitted(false);

      setInputData({
        az: { name: "" },
        en: { name: "" },
        ru: { name: "" },
      });
    } catch (error) {
      setErrorMessage("Xəta baş verdi");
    }
  };

  const validateForm = () => {
    if (
      !inputData.az?.name ||
      !inputData.en?.name ||
      !inputData.ru?.name ||
      !inputData.az?.desc ||
      !inputData.en?.desc ||
      !inputData.ru?.desc ||
      selectedCategoryID === null ||
      imageUrl === "" ||
      activeStatus === null ||
      activeStatus === undefined
    ) {
      setErrorMessage(" Məlumatlar bütün dillərdə doldurulmalıdır.");
      setIsFormSubmitted(true);

      return false;
    }

    setErrorMessage("");
    return true;
  };

  const handleImageRemove = (e) => {
    e.preventDefault();
    setImageUrl("");
    setSelectedImage(null);
  };

  const handlePdfDrop = (acceptedFiles, language) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const selectedPdfFile = acceptedFiles[0];
      if (selectedPdfFile.size > 1024 * 1024) {
        setErrorMessage("Fayl ölçüsü 1 MB-dan böyük olmamalıdır.");
        return;
      }
      setErrorMessage("");

      if (selectedPdfs[language]) {
        const pdfUrl = selectedPdfs[language];
      }

      setSelectedPdfs((prevSelectedPdfs) => ({
        ...prevSelectedPdfs,
        [language]: selectedPdfFile,
      }));

      const formData = new FormData();
      formData.append("file", selectedPdfFile, selectedPdfFile.name);
      formData.append("path", "product");
      setSelectedPdfNames((prevSelectedPdfs) => ({
        ...prevSelectedPdfs,
        [language]: selectedPdfFile.name,
      }));
      fetch(apiUrlUploader, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${apiToken()}`,
          "Accept-Language": language,
          Accept: "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          const uploadedPdfUrl = data.data.file;
          setSelectedPdfs((prevSelectedPdfs) => ({
            ...prevSelectedPdfs,
            [language]: uploadedPdfUrl,
          }));
        });
    } else {
    }
  };

  const handlePDFRemove = (language) => {
    if (selectedPdfs[language]) {
      const pdfUrl = selectedPdfs[language];
      setSelectedPdfs((prevSelectedPdfs) => ({
        ...prevSelectedPdfs,
        [language]: null,
      }));
    }
  };

  const { getRootProps: getImageRootProps, getInputProps: getImageInputProps } =
    useDropzone({
      onDrop: handleImageDrop,
      accept: "image/*",
      multiple: false,
    });

  const { getRootProps: getPdfRootProps, getInputProps: getPdfInputProps } =
    useDropzone({
      onDrop: (acceptedFiles) => handlePdfDrop(acceptedFiles, currentLanguage),
      accept: "application/pdf",
      multiple: false,
    });

  return (
    <>
      <SuccessModal
        successMessage={successMessage}
        onClose={() => setSuccessMessage("")}
      />
      <ErrorModal
        errorMessage={errorMessage}
        onClose={() => setErrorMessage("")}
      />
      <Box m="20px">
        <Header title="Məhsullar" subtitle="Məhsulu əlave et" />
        {loading ? (
          <p>Загрузка...</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <Box
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": {
                  gridColumn: isNonMobile ? undefined : "span 4",
                  width: "100%",
                  marginBottom: "20px",
                },
                "& .MuiBox-root": {
                  display: "flex",
                  flexWrap: "wrap",
                },
                "& > div.Mui-focused": {
                  outline: "1 !important",
                  borderColor: colors.grey[100],
                },
                "& label.Mui-focused ": {
                  color: colors.grey[100],
                },
                "& label.Mui-focused ": {
                  color: colors.grey[100],
                },
              }}
            >
              <TextField
                label="Qiymət"
                name="price"
                value={inputData[currentLanguage]?.price || ""}
                onChange={handleInputChange}
              />
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={activeStatus}
                  onChange={handleStatusChange}
                >
                  <MenuItem value={1}>Aktiv</MenuItem>
                  <MenuItem value={0}>Deaktiv</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Kateqoriya
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                >
                  {Object.keys(category).map((lang) => (
                    <MenuItem key={lang} value={category[lang].name}>
                      {category[lang].name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="Sıra"
                name="order"
                value={inputData[currentLanguage]?.order || ""}
                onChange={handleInputChange}
              />
            </Box>

            <StickyHeader
              currentLanguage={currentLanguage}
              handleLanguageChange={handleLanguageChange}
            />

            <Box
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": {
                  gridColumn: isNonMobile ? undefined : "span 4",
                  width: "100%",
                  marginBottom: "20px",
                },
                "& .MuiBox-root": {
                  display: "flex",
                  flexWrap: "wrap",
                },
                "& > div.Mui-focused": {
                  outline: "1 !important",
                  borderColor: colors.grey[100],
                },
                "& label.Mui-focused ": {
                  color: colors.grey[100],
                },
                "& label.Mui-focused ": {
                  color: colors.grey[100],
                },
              }}
            >
              <TextField
                label="Ad"
                name="name"
                value={inputData[currentLanguage]?.name || ""}
                onChange={handleInputChange}
                error={!inputData[currentLanguage]?.name && isFormSubmitted}
              />
              <MyQuillEditor
                keyProp={currentLanguage}
                name="desc"
                value={inputData[currentLanguage]?.desc || ""}
                onChange={(value) =>
                  handleInputChange({ target: { name: "desc", value } })
                }
                label="Təsvir"
              />
              <FormControl
                fullWidth
                sx={{
                  "& .MuiBox-root": {
                    display: "block !important",
                  },
                }}
              >
                <InputLabel>PDF</InputLabel>
                <Box
                  {...getPdfRootProps()}
                  style={{
                    ...dropzoneStyles,
                    border:
                      !selectedPdfs && isFormSubmitted
                        ? "1px solid red"
                        : "2px dashed #ccc",
                  }}
                >
                  <input
                    {...getPdfInputProps()}
                    onChange={(e) =>
                      handlePdfDrop(e.target.files, currentLanguage)
                    }
                  />
                  {selectedPdfs[currentLanguage] ? (
                    <>
                      <p>Seçilmiş PDF: {selectedPdfNames[currentLanguage]}</p>
                      <button onClick={() => handlePDFRemove(currentLanguage)}>
                        Faylı deyiş
                      </button>
                    </>
                  ) : (
                    <>
                      <Link
                        to={inputData[currentLanguage]?.pdf}
                        style={{ color: colors.grey[100], fontSize: "16px" }}
                      >
                        {inputData[currentLanguage]?.pdf || ""}
                      </Link>
                      <p>
                        Faylı bura sürükləyin və ya fayl seçmək üçün klikləyin.
                      </p>
                      <button onClick={() => handlePDFRemove(currentLanguage)}>
                        Faylı deyiş
                      </button>
                    </>
                  )}
                </Box>
              </FormControl>
              <div
                {...getImageRootProps()}
                style={{
                  ...dropzoneStyles,
                  border:
                    !imageUrl && isFormSubmitted
                      ? "1px solid red"
                      : "2px dashed #ccc",
                }}
              >
                <input {...getImageInputProps()} />
                {selectedImage ? (
                  <>
                    <p>Seçilmiş şəkil: {selectedImage.name}</p>
                    <img
                      src={URL.createObjectURL(selectedImage)}
                      alt="Seçilmiş şəkil"
                      style={{ maxWidth: "100%", maxHeight: "200px" }}
                    />
                    <button onClick={handleImageRemove}>Şəkili deyiş</button>
                  </>
                ) : (
                  <>
                    <img
                      src={getData.image}
                      alt="Seçilmiş şəkil"
                      style={{ maxWidth: "100%", maxHeight: "200px" }}
                    />
                    <button onClick={handleImageRemove}>Şəkili deyiş</button>
                  </>
                )}
              </div>
              <ImagesUpload
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
                isDragging={isDragging}
                selectFiles={selectFiles}
                fileInputRef={fileInputRef}
                onFilesSelect={onFilesSelect}
                images={images}
                uploadImages={uploadImages}
                deleteImage={deleteImage}
              />
            </Box>
          </form>
        )}
      </Box>
    </>
  );
}

export default ProductAdd;

const dropzoneStyles = {
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};
