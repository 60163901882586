import React, { useEffect } from "react";
import { Box } from "@mui/material";
import Header from "../components/Header";
import { useRequireToken } from "../functions/authUtils";
import setPageTitle from "../functions/setTitle";

function Dashboard() {
  const title = setPageTitle("home");
  document.title = title;
  useRequireToken();
  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to our dashboard" />
      </Box>
    </Box>
  );
}

export default Dashboard;
