import React, { useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DOMPurify from "dompurify";

const MyQuillEditor = ({ value, onChange, label, keyProp }) => {
  const quillModules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["clean"],
    ],
  };

  const quillFormats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
  ];

  const quillConfig = {
    formats: quillFormats,
    modules: quillModules,
    theme: "snow",
    formats: [
      "header",
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "list",
      "bullet",
      "link",
    ],
    modules: {
      toolbar: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
      clipboard: {
        matchVisual: false,
      },
    },
  };

  const prevContentRef = useRef();

  const handleChange = (content, delta, source, editor) => {
    if (source === "user") {
      const cleanedContent = DOMPurify.sanitize(content);

      if (cleanedContent !== prevContentRef.current) {
        prevContentRef.current = cleanedContent;
        onChange(cleanedContent);
      }
    }
  };

  useEffect(() => {
    prevContentRef.current = value;
  }, [value]);

  return (
    <div key={keyProp} style={{ marginBottom: "20px" }}>
      {label && (
        <label
          style={{
            color: "rgba(255, 255, 255, 0.7)",
            marginBottom: "15px",
            display: "inline-block",
          }}
        >
          {label}
        </label>
      )}
      <ReactQuill value={value} onChange={handleChange} {...quillConfig} />
    </div>
  );
};

export default MyQuillEditor;
