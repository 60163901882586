import CategoryEdit from "../components/CategoryEdit";
import setPageTitle from "../functions/setTitle";

function CategoryProductEdit() {
  const title = setPageTitle("category");
  document.title = title;
  return (
    <>
      <CategoryEdit />
    </>
  );
}

export default CategoryProductEdit;
