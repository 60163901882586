import { useEffect } from "react";
import { getToken } from "./tokenService";
import useCheckTokenExpiration from "./CheckTokenExpiration";

export const useRequireToken = () => {
  const checkTokenExpiration = useCheckTokenExpiration();
  useEffect(() => {
    checkTokenExpiration();
  }, []);
  const token = getToken();
  if (!token) {
    window.location.href = "/login";
    return null;
  }
};
