import React, { useState, useEffect } from "react";
import { Box, Button, TextField, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "../theme";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import SuccessModal from "../components/SuccessModal";
import ErrorModal from "../components/ErrorModal";
import { handleUnauthorizedResponse } from "../functions/tokenService";
import { useRequireToken } from "../functions/authUtils";
import useCheckTokenExpiration from "../functions/CheckTokenExpiration";
import apiToken from "../functions/tokenUtils";
import setPageTitle from "../functions/setTitle";
import * as consts from "../consts/Consts";

function Social() {
  const title = setPageTitle("social");
  document.title = title;
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentLanguage, setCurrentLanguage] = useState("az");
  const [contactData, setContactData] = useState({});
  const [loading, setLoading] = useState(true);
  const [inputData, setInputData] = useState({});
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const apiToken = localStorage.getItem("token");
  const apiUrl = `${consts.API_URL}/contact/edit`;
  const apiUrlPut = `${consts.API_URL}/contact`;

  const checkTokenExpiration = useCheckTokenExpiration();

  useEffect(() => {
    checkTokenExpiration();
  }, [checkTokenExpiration]);

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${apiToken()}`,
      "Accept-Language": currentLanguage,
      "Content-Type": "application/json",
    };

    fetch(apiUrl, {
      headers,
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            handleUnauthorizedResponse(response);
          } else {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
        }
        return response.json();
      })
      .then((data) => {
        setContactData(data.data);
        setLoading(false);

        setInputData({
          phone: data.data.phone || "",
          email: data.data.email || "",
          facebook: data.data.facebook || "",
          instagram: data.data.instagram || "",
          twitter: data.data.twitter || "",
          youtube: data.data.youtube || "",
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [apiUrl, apiToken, currentLanguage]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setInputData((prevInputData) => ({
      ...prevInputData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const isValid = validateForm();
    if (!isValid) {
      return;
    }
    const updatedData = {
      email: inputData.email || "",
      phone: inputData.phone || "",
      facebook: inputData.facebook || "",
      instagram: inputData.instagram || "",
      youtube: inputData.youtube || "",
      twitter: inputData.twitter || "",
      translations: contactData.translations,
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${apiToken()}`,
        "Accept-Language": currentLanguage,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedData),
    };

    fetch(apiUrlPut, requestOptions)
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            handleUnauthorizedResponse(response);
          } else {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
        }
        return response.json();
      })
      .then((data) => {
        setSuccessMessage("Forma uğurla göndərildi!");
      })
      .catch((error) => {
        setErrorMessage("Xəta baş verdi");
      });
  };

  const validateForm = () => {
    if (
      !inputData.phone ||
      !inputData.email ||
      !inputData.instagram ||
      !inputData.facebook ||
      !inputData.youtube ||
      !inputData.twitter
    ) {
      setErrorMessage("Məlumatlar doldurulmalıdır.");
      setIsFormSubmitted(true);

      return false;
    }

    setErrorMessage("");
    return true;
  };

  return (
    <>
      <SuccessModal
        successMessage={successMessage}
        onClose={() => setSuccessMessage("")}
      />
      <ErrorModal
        errorMessage={errorMessage}
        onClose={() => setErrorMessage("")}
      />

      <Box m="20px">
        <Header
          title="Əlaqə məlumatları"
          subtitle="Əlaqə məlumatlarda düzəliş et"
        />

        {loading ? (
          <p>Загрузка...</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <Box
              display="flex"
              justifyContent="end"
              mt="20px"
              mb="20px"
              position="sticky"
              top="0"
              zIndex="100"
            >
              <Button type="submit" color="secondary" variant="contained">
                Düzəliş et
              </Button>
            </Box>
            <Box
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": {
                  gridColumn: isNonMobile ? undefined : "span 4",
                  width: "100%",
                  marginBottom: "20px",
                },
                "& .MuiBox-root": {
                  display: "flex",
                  flexWrap: "wrap",
                },
                "& > div.Mui-focused": {
                  outline: "1 !important",
                  borderColor: colors.grey[100],
                },
                "& > div.Mui-focused ": {
                  borderColor: colors.grey[100],
                },
                "& label.Mui-focused ": {
                  color: colors.grey[100],
                },
                "& label.Mui-focused ": {
                  color: colors.grey[100],
                },
              }}
            >
              <TextField
                label="Telefon nömrəsi"
                name="phone"
                value={inputData.phone || ""}
                onChange={handleInputChange}
                error={!inputData.phone && isFormSubmitted}
              />
              <TextField
                label="Email"
                name="email"
                value={inputData.email || ""}
                onChange={handleInputChange}
                error={!inputData.email && isFormSubmitted}
              />
              <TextField
                label="Facebook"
                name="facebook"
                value={inputData.facebook || ""}
                onChange={handleInputChange}
                error={!inputData.facebook && isFormSubmitted}
              />
              <TextField
                label="Instagram"
                name="instagram"
                value={inputData.instagram || ""}
                onChange={handleInputChange}
                error={!inputData.instagram && isFormSubmitted}
              />
              <TextField
                label="Twitter"
                name="twitter"
                value={inputData.twitter || ""}
                onChange={handleInputChange}
                error={!inputData.twitter && isFormSubmitted}
              />
              <TextField
                label="Youtube"
                name="youtube"
                value={inputData.youtube || ""}
                onChange={handleInputChange}
                error={!inputData.youtube && isFormSubmitted}
              />
            </Box>
          </form>
        )}
      </Box>
    </>
  );
}

export default Social;
