import React from "react";
import SubCategoryEdit from "../components/SubCategoryEdit";
import setPageTitle from "../functions/setTitle";

function SubCategoryProductEdit() {
  const title = setPageTitle("subcategory");
  document.title = title;
  return <SubCategoryEdit />;
}

export default SubCategoryProductEdit;
