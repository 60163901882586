import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Modal, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../theme";
import Header from "../components/Header";
import { Link, useNavigate, useLocation } from "react-router-dom";
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import DeleteConfirmationModal from "../components/DeleteConfirmationModal";
import SuccessAlert from "../components/SuccessAlert";
import { handleUnauthorizedResponse } from "../functions/tokenService";
import { useRequireToken } from "../functions/authUtils";
import useCheckTokenExpiration from "../functions/CheckTokenExpiration";
import apiToken from "../functions/tokenUtils";
import setPageTitle from "../functions/setTitle";
import * as consts from "../consts/Consts";

function Solution() {
  const title = setPageTitle("solutions");
  document.title = title;
  useRequireToken();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [getData, setgetData] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState("az");
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState({});

  const apiUrl = `${consts.API_URL_SITE}/solution`;
  const apiCategoryGet = `${consts.API_URL_SITE}/category/solution`;

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [successMessage, setSuccessMessage] = useState(
    searchParams.get("successMessage")
  );

  const checkTokenExpiration = useCheckTokenExpiration();

  useEffect(() => {
    checkTokenExpiration();
  }, [checkTokenExpiration]);

  useEffect(() => {
    const headers = {
      "Accept-Language": currentLanguage,
      "Content-Type": "application/json",
    };

    fetch(apiCategoryGet, {
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data) && data.length > 0) {
          const categoryData = data.map((item) => ({
            ...item,
            sub: item.sub || [],
          }));

          const allSubCategories = categoryData.flatMap((item) => item.sub);
          setCategory(allSubCategories);
        } else {
          setCategory([]);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [currentLanguage]);

  const categoryMap = {};

  useEffect(() => {
    const categoryArray = Object.values(category);
    categoryArray.forEach((categoryItem) => {
      categoryMap[categoryItem.id] = categoryItem.name;
    });
  }, [category]);

  const handleEditClick = (params) => {
    const itemId = params.row.id;
    navigate(`edit/${itemId}`);
  };

  const handleDeleteClick = (params) => {
    setSelectedItem(params.row);
    setDeleteModalOpen(true);
  };

  const handleDelete = async () => {
    setDeleteModalOpen(false);

    if (!selectedItem) {
      return;
    }

    const deleteUrl = `${consts.API_URL}/solution/${selectedItem.id}`;

    console.log(deleteUrl);

    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${apiToken()}`,
        "Accept-Language": currentLanguage,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(deleteUrl, requestOptions);

      if (!response.ok) {
        if (response.status === 401) {
          handleUnauthorizedResponse(response);
        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      }
      // console.log("Успешно удалено");

      setgetData((prevgetData) =>
        prevgetData.filter((news) => news.id !== selectedItem.id)
      );
    } catch (error) {
      // console.error("Ошибка при удалении данных:", error);
    }
  };

  useEffect(() => {
    const headers = {
      "Accept-Language": currentLanguage,
      "Content-Type": "application/json",
    };

    fetch(apiUrl, {
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setgetData(data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Ошибка при получении данных:", error);
        setLoading(false);
      });
  }, [apiUrl, currentLanguage]);

  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "image",
      headerName: "Şəkil",
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <img src={params.value} alt="Şəkil" width={50} height={50} />
          </Grid>
        </Grid>
      ),
    },
    {
      field: "name",
      headerName: "Ad",
      flex: 1,
      cellClassName: "name-column--cell w-15",
      width: "15%",
    },
    {
      field: "solution_category_id",
      headerName: "Kateqoriya",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: (params) => <span>{categoryMap[params.value] || "N/A"}</span>,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <span>
          {params.value === 1 ? "Aktiv" : params.value === 0 ? "Deaktiv" : ""}
        </span>
      ),
    },
    {
      field: "actions",
      headerName: "",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEditClick(params)}
          >
            Düzəliş et
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDeleteClick(params)}
          >
            Sil
          </Button>
        </div>
      ),
    },
  ];

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <>
      <DeleteConfirmationModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={handleDelete}
      />
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Solution" subtitle="Solution siyahısı" />
          <Link to="add">
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.primary[100],
              }}
              startIcon={<LibraryAddOutlinedIcon />}
            >
              Əlavə et
            </Button>
          </Link>
        </Box>

        {successMessage && (
          <SuccessAlert
            message={successMessage}
            onClose={() => setSuccessMessage("")}
          />
        )}

        <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
              fontSize: "18px !important",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
              fontSize: "14px !important",
            },
            "& .name-column--cell": {
              fontSize: "16px !important",
            },
            "& button.MuiButtonBase-root": {
              marginRight: "10px",
            },
          }}
        >
          <DataGrid
            rows={getData}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
          />
        </Box>
      </Box>
    </>
  );
}

export default Solution;
