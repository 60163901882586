import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, TextField, useTheme } from "@mui/material";
import { tokens } from "../theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../components/Header";
import { useDropzone } from "react-dropzone";
import StickyHeader from "../components/StickyHeader";
import SuccessModal from "../components/SuccessModal";
import ErrorModal from "../components/ErrorModal";
import { handleUnauthorizedResponse } from "../functions/tokenService";
import { useRequireToken } from "../functions/authUtils";
import useCheckTokenExpiration from "../functions/CheckTokenExpiration";
import apiToken from "../functions/tokenUtils";
import setPageTitle from "../functions/setTitle";
import * as consts from "../consts/Consts";

function ContactUs() {
  const title = setPageTitle("contact");
  document.title = title;
  useRequireToken();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentLanguage, setCurrentLanguage] = useState("az");
  const [contactData, setContactData] = useState({});
  const [loading, setLoading] = useState(true);
  const [inputData, setInputData] = useState({});
  const [imageUrl, setImageUrl] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [isImageChanged, setImageChanged] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const apiUrl = `${consts.API_URL}/contact/edit`;
  const apiUrlUploader = `${consts.API_URL}/uploader`;
  const apiUrlPut = `${consts.API_URL}/contact`;

  const navigate = useNavigate();

  const checkTokenExpiration = useCheckTokenExpiration();

  useEffect(() => {
    checkTokenExpiration();
  }, [checkTokenExpiration]);

  const handleLanguageChange = (event, newValue) => {
    setCurrentLanguage(newValue);

    if (pageLoaded) {
      setInputData((prevInputData) => {
        const currentInputData = { ...prevInputData };
        return currentInputData;
      });

      if (isImageChanged) {
        handleImageDrop([selectedImage]);
      }
    } else {
      fetchDataForLanguage(newValue);
    }
  };

  const fetchDataForLanguage = (language) => {
    const headers = {
      Authorization: `Bearer ${apiToken()}`,
      "Accept-Language": language,
      "Content-Type": "application/json",
    };

    fetch(apiUrl, {
      headers,
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            handleUnauthorizedResponse(navigate);
          } else {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
        }
        return response.json();
      })
      .then((data) => {
        console.log("Ответ на GET-запрос:", data);

        if (data && data.data) {
          setContactData(data.data);
          setLoading(false);

          const commonEmail = data.data.email || "";
          const commonPhone = data.data.phone || "";

          if (pageLoaded) {
            const currentInputData = { ...inputData };
            const languages = ["az", "ru", "en"];

            currentInputData.email = commonEmail;
            currentInputData.phone = commonPhone;

            // languages.forEach((lang) => {
            //   currentInputData[lang].email = commonEmail;
            //   currentInputData[lang].phone = commonPhone;
            // });

            setInputData(currentInputData);
          } else {
            const updatedInputData = {};
            const languages = ["az", "ru", "en"];

            languages.forEach((lang) => {
              updatedInputData[lang] = {
                title_text:
                  data.data.translations.find((t) => t.lang_code === lang)
                    ?.title_text || "",
                address:
                  data.data.translations.find((t) => t.lang_code === lang)
                    ?.address || "",
                opening_hours:
                  data.data.translations.find((t) => t.lang_code === lang)
                    ?.opening_hours || "",
                email: commonEmail,
                phone: commonPhone,
              };
            });

            setInputData(updatedInputData);
            setPageLoaded(true);
          }

          setImageUrl(data.data.image);
        } else {
          console.error("Получены некорректные данные:", data);
        }
      })
      .catch((error) => {
        console.error("Ошибка при получении данных:", error);
        setLoading(false);
      });
    if (isImageChanged) {
      const selectedImageFile = selectedImage;

      if (selectedImageFile) {
        const formData = new FormData();
        formData.append("file", selectedImageFile, selectedImageFile.name);
        formData.append("path", "about");

        fetch(apiUrlUploader, {
          method: "POST",
          body: formData,
          headers: {
            "api-token": apiToken,
            "Accept-Language": language,
            Accept: "application/json",
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          })
          .then((data) => {
            console.log("Успешно загружено:", data);
            const uploadedImageUrl = data.data.file;
            console.log("URL изображения:", uploadedImageUrl);

            setImageUrl(uploadedImageUrl);
            console.log(uploadedImageUrl);
          })
          .catch((error) => {
            console.error("Ошибка при загрузке файла:", error);
          });
      }
    }
  };

  useEffect(() => {
    fetchDataForLanguage(currentLanguage);
  }, [currentLanguage]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setInputData((prevTranslations) => {
      const currentTranslations = { ...prevTranslations };
      currentTranslations[currentLanguage] = {
        ...currentTranslations[currentLanguage],
        [name]: value,
      };
      console.log(currentTranslations);
      return currentTranslations;
    });
  };

  const handleImageDrop = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const selectedImageFile = acceptedFiles[0];
      if (selectedImageFile.size > 1024 * 1024) {
        setErrorMessage("Fayl ölçüsü 1 MB-dan böyük olmamalıdır.");
        return;
      }
      setErrorMessage("");
      const formData = new FormData();
      formData.append("file", selectedImageFile, selectedImageFile.name);
      formData.append("path", "about");

      fetch(apiUrlUploader, {
        method: "POST",
        body: formData,
        headers: {
          //  "api-token": apiToken,
          Authorization: `Bearer ${apiToken()}`,
          "Accept-Language": currentLanguage,
          Accept: "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log("Успешно загружено:", data);
          const uploadedImageUrl = data.data.file;
          console.log("URL изображения:", uploadedImageUrl);

          setImageUrl(uploadedImageUrl);
          setSelectedImage(selectedImageFile);
          setImageChanged(true);

          console.log(uploadedImageUrl);
        })
        .catch((error) => {
          console.error("Ошибка при загрузке файла:", error);
        });
    } else {
      console.log("Изображение не выбрано");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const isValid = validateForm();
    if (!isValid) {
      return;
    }

    const updatedData = {
      email: inputData[currentLanguage]?.email || "",
      phone: inputData[currentLanguage]?.phone || "",
      image: imageUrl,
      facebook: contactData.facebook,
      instagram: contactData.instagram,
      youtube: contactData.youtube,
      twitter: contactData.twitter,
      translations: [],
    };

    const languages = ["az", "ru", "en"];
    languages.forEach((lang) => {
      updatedData.translations.push({
        lang_code: lang,
        title_text: inputData[lang]?.title_text || "",
        email: inputData[lang]?.email || "",
        phone: inputData[lang]?.phone || "",
        address: inputData[lang]?.address || "",
        opening_hours: inputData[lang]?.opening_hours || "",
      });
    });

    if (!isImageChanged) {
      delete updatedData.image;
    } else {
      updatedData.image = imageUrl;
    }

    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${apiToken()}`,
        "Accept-Language": currentLanguage,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedData),
    };

    fetch(apiUrlPut, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setSuccessMessage("Forma uğurla göndərildi!");
      })
      .catch((error) => {
        setErrorMessage("Xəta baş verdi");
      });
  };

  const validateForm = () => {
    if (
      !inputData.az?.title_text ||
      !inputData.en?.title_text ||
      !inputData.ru?.title_text ||
      !inputData.az?.email ||
      !inputData.en?.email ||
      !inputData.ru?.email ||
      !inputData.az?.phone ||
      !inputData.en?.phone ||
      !inputData.ru?.phone ||
      !inputData.az?.opening_hours ||
      !inputData.en?.opening_hours ||
      !inputData.ru?.opening_hours ||
      !inputData.az?.address ||
      !inputData.en?.address ||
      !inputData.ru?.address ||
      imageUrl === null
    ) {
      setErrorMessage(" Məlumatlar bütün dillərdə doldurulmalıdır.");
      setIsFormSubmitted(true);

      return false;
    }

    setErrorMessage("");
    return true;
  };

  const handleImageRemove = (e) => {
    e.preventDefault();
    setImageUrl("");
    setSelectedImage(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleImageDrop,
    accept: "image/*",
    multiple: false,
  });

  return (
    <>
      <SuccessModal
        successMessage={successMessage}
        onClose={() => setSuccessMessage("")}
      />
      <ErrorModal
        errorMessage={errorMessage}
        onClose={() => setErrorMessage("")}
      />
      <Box m="20px">
        <Header
          title="Bizimlə əlaqə"
          subtitle="Bizimlə əlaqə səhifəyə düzəliş et"
        />

        {loading ? (
          <p>Загрузка...</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <StickyHeader
              currentLanguage={currentLanguage}
              handleLanguageChange={handleLanguageChange}
            />
            <Box
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": {
                  gridColumn: isNonMobile ? undefined : "span 4",
                  width: "100%",
                  marginBottom: "20px",
                },
                "& .MuiBox-root": {
                  display: "flex",
                  flexWrap: "wrap",
                },
                "& > div.Mui-focused": {
                  outline: "1 !important",
                  borderColor: colors.grey[100],
                },
                "& > div.Mui-focused ": {
                  borderColor: colors.grey[100],
                },
                "& label.Mui-focused ": {
                  color: colors.grey[100],
                },
                "& label.Mui-focused ": {
                  color: colors.grey[100],
                },
              }}
            >
              <TextField
                label="Mətn"
                name="title_text"
                value={inputData[currentLanguage]?.title_text || ""}
                onChange={handleInputChange}
                error={
                  !inputData[currentLanguage]?.title_text && isFormSubmitted
                }
              />
              <TextField
                label="Email"
                name="email"
                value={inputData[currentLanguage]?.email || ""}
                onChange={handleInputChange}
                error={!inputData[currentLanguage]?.email && isFormSubmitted}
              />
              <TextField
                label="Telefon nömrəsi"
                name="phone"
                value={inputData[currentLanguage]?.phone || ""}
                onChange={handleInputChange}
                error={!inputData[currentLanguage]?.phone && isFormSubmitted}
              />
              <TextField
                label="Saatlar"
                name="opening_hours"
                value={inputData[currentLanguage]?.opening_hours || ""}
                onChange={handleInputChange}
                error={
                  !inputData[currentLanguage]?.opening_hours && isFormSubmitted
                }
              />
              <TextField
                label="Ünvan"
                name="address"
                value={inputData[currentLanguage]?.address || ""}
                onChange={handleInputChange}
                error={!inputData[currentLanguage]?.address && isFormSubmitted}
              />
              <div
                {...getRootProps()}
                style={{
                  ...dropzoneStyles,
                  border:
                    !imageUrl && isFormSubmitted
                      ? "1px solid red"
                      : "2px dashed #ccc",
                }}
              >
                <input {...getInputProps()} />
                {selectedImage ? (
                  <>
                    <p>Выбрано изображение: {selectedImage.name}</p>
                    <img
                      src={URL.createObjectURL(selectedImage)}
                      alt="Выбранное изображение"
                      style={{ maxWidth: "100%", maxHeight: "200px" }}
                    />
                    <button onClick={handleImageRemove}>Şəkili deyiş</button>
                  </>
                ) : (
                  <>
                    <img
                      src={contactData.image}
                      alt="Выбранное изображение"
                      style={{ maxWidth: "100%", maxHeight: "200px" }}
                    />
                    <button onClick={handleImageRemove}>Şəkili deyiş</button>
                  </>
                )}
              </div>
            </Box>
          </form>
        )}
      </Box>
    </>
  );
}

export default ContactUs;

const dropzoneStyles = {
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};
