import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  TextField,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  useTheme,
} from "@mui/material";
import { tokens } from "../theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../components/Header";
import { useDropzone } from "react-dropzone";
import StickyHeader from "../components/StickyHeader";
import SuccessModal from "../components/SuccessModal";
import ErrorModal from "../components/ErrorModal";
import { handleUnauthorizedResponse } from "../functions/tokenService";
import { useRequireToken } from "../functions/authUtils";
import useCheckTokenExpiration from "../functions/CheckTokenExpiration";
import apiToken from "../functions/tokenUtils";
import setPageTitle from "../functions/setTitle";
import * as consts from "../consts/Consts";

function SliderEdit() {
  const title = setPageTitle("slider");
  document.title = title;
  useRequireToken();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentLanguage, setCurrentLanguage] = useState("az");
  const [getData, setgetData] = useState({});
  const [loading, setLoading] = useState(true);
  const [inputData, setInputData] = useState({});
  const [imageUrl, setImageUrl] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [isImageChanged, setImageChanged] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const params = useParams();
  const { itemId } = params;

  const apiUrl = `${consts.API_URL}/slider/${itemId}/edit`;
  const apiUrlPut = `${consts.API_URL}/slider/${itemId}`;
  const apiUrlUploader = `${consts.API_URL}/uploader`;

  const checkTokenExpiration = useCheckTokenExpiration();

  useEffect(() => {
    checkTokenExpiration();
  }, [checkTokenExpiration]);

  const [activeStatus, setActiveStatus] = useState("");
  const [status, setStatus] = useState(activeStatus);
  const handleStatusChange = (event) => {
    const selectedStatus = event.target.value;
    setActiveStatus(selectedStatus);
    setStatus(selectedStatus);
  };

  const handleLanguageChange = (event, newValue) => {
    setCurrentLanguage(newValue);

    if (isImageChanged) {
      handleImageDrop([selectedImage]);
    }

    const commonStatus = inputData[currentLanguage]?.status || "";

    setInputData((prevInputData) => {
      const updatedInputData = { ...prevInputData };

      updatedInputData.az.status =
        updatedInputData.ru.status =
        updatedInputData.en.status =
          commonStatus;

      return updatedInputData;
    });
  };

  const fetchDataForLanguage = (language) => {
    const headers = {
      Authorization: `Bearer ${apiToken()}`,
      "Accept-Language": language,
      "Content-Type": "application/json",
    };

    fetch(apiUrl, {
      headers,
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            handleUnauthorizedResponse(response);
          } else {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
        }
        return response.json();
      })
      .then((data) => {
        if (data && data.data) {
          setgetData(data.data);
          setLoading(false);

          const commonStatus = data.data.status;
          setActiveStatus(commonStatus);
          if (pageLoaded) {
            setInputData((prevInputData) => ({
              ...prevInputData,
              status: commonStatus,
            }));
          } else {
            const updatedInputData = {};
            const languages = ["az", "ru", "en"];

            languages.forEach((lang) => {
              updatedInputData[lang] = {
                title:
                  data.data.translations.find((t) => t.lang_code === lang)
                    ?.title || "",
                button_url:
                  data.data.translations.find((t) => t.lang_code === lang)
                    ?.button_url || "",
                status: commonStatus,
              };
            });
            console.log(data.data);

            setInputData(updatedInputData);
            setPageLoaded(true);
          }

          setImageUrl(data.data.image);
        } else {
          //  console.error("Получены некорректные данные:", data);
        }
      })
      .catch((error) => {
        //   console.error("Ошибка при получении данных:", error);

        setLoading(false);
      });
    if (isImageChanged) {
      const selectedImageFile = selectedImage;

      if (selectedImageFile) {
        const formData = new FormData();
        formData.append("file", selectedImageFile, selectedImageFile.name);
        formData.append("path", "slider");

        fetch(apiUrlUploader, {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${apiToken()}`,
            "Accept-Language": language,
            Accept: "application/json",
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          })
          .then((data) => {
            // console.log("Успешно загружено:", data);
            const uploadedImageUrl = data.data.file;
            // console.log("URL изображения:", uploadedImageUrl);

            setImageUrl(uploadedImageUrl);
          })
          .catch((error) => {
            // console.error("Ошибка при загрузке файла:", error);
          });
      }
    }
  };

  useEffect(() => {
    fetchDataForLanguage(currentLanguage);
  }, [currentLanguage]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setInputData((prevTranslations) => {
      const currentTranslations = { ...prevTranslations };
      currentTranslations[currentLanguage] = {
        ...currentTranslations[currentLanguage],
        [name]: value,
      };
      return currentTranslations;
    });
  };

  const handleImageDrop = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const selectedImageFile = acceptedFiles[0];
      if (selectedImageFile.size > 1024 * 1024) {
        setErrorMessage("Fayl ölçüsü 1 MB-dan böyük olmamalıdır.");
        return;
      }
      setErrorMessage("");
      const formData = new FormData();
      formData.append("file", selectedImageFile, selectedImageFile.name);
      formData.append("path", "slider");

      fetch(apiUrlUploader, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${apiToken()}`,
          "Accept-Language": currentLanguage,
          Accept: "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          //  console.log("Успешно загружено:", data);
          const uploadedImageUrl = data.data.file;
          //  console.log("URL изображения:", uploadedImageUrl);

          setImageUrl(uploadedImageUrl);
          setSelectedImage(selectedImageFile);
          setImageChanged(true);
        })
        .catch((error) => {
          //  console.error("Ошибка при загрузке файла:", error);
        });
    } else {
      // console.log("Изображение не выбрано");
    }
  };

  function isValidUrl(url) {
    const urlPattern = /^(https?|ftp):\/\/\S+$/;
    return urlPattern.test(url);
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const isValid = validateForm();
    if (!isValid) {
      return;
    }

    const updatedData = {
      status: activeStatus,
      translations: [],
    };

    const languages = ["az", "ru", "en"];
    let isValidURL = true;

    for (const lang of languages) {
      if (
        inputData[lang]?.button_url &&
        !isValidUrl(inputData[lang]?.button_url)
      ) {
        setErrorMessage(`'button_url' dəyəri etibarlı keçid olmalıdır.`);
        isValidURL = false;
        setIsFormSubmitted(true);
        break;
      }

      updatedData.translations.push({
        lang_code: lang,
        title: inputData[lang]?.title || "",
        button_url: inputData[lang]?.button_url || "",
      });
    }

    if (!isValidURL) {
      return;
    }

    if (!isImageChanged) {
      delete updatedData.image;
    } else {
      updatedData.image = imageUrl;
    }

    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${apiToken()}`,
        "Accept-Language": currentLanguage,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedData),
    };

    fetch(apiUrlPut, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setSuccessMessage("Forma uğurla göndərildi!");
      })
      .catch((error) => {
        setErrorMessage("Xəta baş verdi");
      });
  };

  const validateForm = () => {
    if (
      imageUrl === null ||
      activeStatus === null ||
      activeStatus === undefined
    ) {
      setErrorMessage(" Məlumatlar bütün dillərdə doldurulmalıdır.");
      setIsFormSubmitted(true);

      return false;
    }

    setErrorMessage("");
    return true;
  };

  const handleImageRemove = (e) => {
    e.preventDefault();
    setImageUrl("");
    setSelectedImage(null);
  };

  const { getRootProps: getImageRootProps, getInputProps: getImageInputProps } =
    useDropzone({
      onDrop: handleImageDrop,
      accept: "image/*",
      multiple: false,
    });

  return (
    <>
      <SuccessModal
        successMessage={successMessage}
        onClose={() => setSuccessMessage("")}
      />
      <ErrorModal
        errorMessage={errorMessage}
        onClose={() => setErrorMessage("")}
      />

      <Box m="20px">
        <Header title="Slayderlər" subtitle="Slayderə düzəliş et" />
        {loading ? (
          <p>Загрузка...</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <StickyHeader
              currentLanguage={currentLanguage}
              handleLanguageChange={handleLanguageChange}
            />
            <Box
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": {
                  gridColumn: isNonMobile ? undefined : "span 4",
                  width: "100%",
                  marginBottom: "20px",
                },
                "& .MuiBox-root": {
                  display: "flex",
                  flexWrap: "wrap",
                },
                "& > div.Mui-focused": {
                  outline: "1 !important",
                  borderColor: colors.grey[100],
                },
                "& label.Mui-focused ": {
                  color: colors.grey[100],
                },
                "& label.Mui-focused ": {
                  color: colors.grey[100],
                },
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={activeStatus}
                  onChange={handleStatusChange}
                >
                  <MenuItem value={1}>Aktiv</MenuItem>
                  <MenuItem value={0}>Deaktiv</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": {
                  gridColumn: isNonMobile ? undefined : "span 4",
                  width: "100%",
                  marginBottom: "20px",
                },
                "& .MuiBox-root": {
                  display: "flex",
                  flexWrap: "wrap",
                },
                "& > div.Mui-focused": {
                  outline: "1 !important",
                  borderColor: colors.grey[100],
                },
                "& label.Mui-focused ": {
                  color: colors.grey[100],
                },
                "& label.Mui-focused ": {
                  color: colors.grey[100],
                },
              }}
            >
              <TextField
                label="Başlıq"
                name="title"
                value={inputData[currentLanguage]?.title || ""}
                onChange={handleInputChange}
                //  error={!inputData[currentLanguage]?.title && isFormSubmitted}
              />
              <TextField
                label="Düymə URL"
                name="button_url"
                value={inputData[currentLanguage]?.button_url || ""}
                onChange={handleInputChange}
                error={
                  !isValidUrl(inputData[currentLanguage]?.button_url) &&
                  isFormSubmitted
                }
                placeholder="Məsələn, https://www.google.com/"
              />

              <div
                {...getImageRootProps()}
                style={{
                  ...dropzoneStyles,
                  border:
                    !imageUrl && isFormSubmitted
                      ? "1px solid red"
                      : "2px dashed #ccc",
                }}
              >
                <input {...getImageInputProps()} />
                {selectedImage ? (
                  <>
                    <p>Seçilmiş şəkil: {selectedImage.name}</p>
                    <img
                      src={URL.createObjectURL(selectedImage)}
                      alt="Seçilmiş şəkil"
                      style={{ maxWidth: "100%", maxHeight: "200px" }}
                    />
                    <button onClick={handleImageRemove}>Şəkili deyiş</button>
                  </>
                ) : (
                  <>
                    <img
                      src={getData.image}
                      alt="Seçilmiş şəkil"
                      style={{ maxWidth: "100%", maxHeight: "200px" }}
                    />
                    <button onClick={handleImageRemove}>Şəkili deyiş</button>
                  </>
                )}
              </div>
            </Box>
          </form>
        )}
      </Box>
    </>
  );
}

export default SliderEdit;

const dropzoneStyles = {
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};
